import React, { Component } from 'react';

export class Forsida extends Component {

    componentDidMount () {

        this.onResize();
        (new ResizeObserver(_ => this.onResize())).observe(document.body);
    }

    onResize () {
        // Obviously you can format your data object however you want
        window.parent.postMessage({
            type: 'resize',
            height: document.body.scrollHeight,
        }, '*');
    }

    render() {
        return (
            <div className="wrapperReikn">
                <h2>Reiknivélar</h2>
                <h3><strong className='thakcolor'>þak</strong>Co</h3>
                <ul class="val_reiknivelar thakval">
                    <li class="val_thakpappi"><a href="/thakpappi"><div class="icon"></div>Þakkpappi</a></li>
                    <li class="val_thakbara"><a href="/thakbara"><div class="icon"></div>Þakbára</a></li>
                </ul>
                <h3><strong className='fagcolor'>Fag</strong>Co</h3>
                <ul class="val_reiknivelar fagval">
                    <li class="val_parket"><a href="/parket"><div class="icon"></div>Parket</a></li>
                    <li class="val_innihurdir"><a href="/innihurdir"><div class="icon"></div>Innihurðir</a></li>
                    <li class="val_milliveggir"><a href="/milliveggir"><div class="icon"></div>Milliveggir</a></li>
                    <li class="val_innrettingar"><a href="/innrettingar"><div class="icon"></div>Innréttingar</a></li>
                </ul>
            </div>
        );
    }
}

export default Forsida;