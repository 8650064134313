import React, { Component } from 'react';
import axios from 'axios';
import NumberFormat from 'react-number-format';
export class Reiknadu extends Component {
    
    constructor(props) {
        super(props)
        this.state = {
            currentStep: 1,
            flatarmal:  '20',
            tegund: '',
            kantur: '',
            einangrun: '',
            einangruntegund: '',
            gogn: '',
            fm1_15: '',
            fm16_50: '',
            fm51_499: '',
            einangrun_xps: '',
            xps_2x100mm: '',
            drendukur: '',
            malarfarg: '',
            einangrun_ull: '',
            ull_3_log: '',
            rakasperra: '',
            torf: '',
            torf_2_log: '',
            drendukur_torf: '',
            utskyring_1: '',
            utskyring_2: '',
            utskyring_3: '',
            utskyring_4: '',
            utskyring_5: '',
            utskyring_6: '',
            utskyring_7: '',
            titillsida: 'Reiknaðu',
            verkbyrjun:'',
        }
    }

    componentDidMount () {

        this.onResize();
        (new ResizeObserver(_ => this.onResize())).observe(document.body);

        axios.get('https://content.thakco.is/wp-json/wp/v2/pages/14')
        .then(res => this.setState({
            gogn : res.data.acf.lysing, 
            fm1_15: res.data.acf.fm1_15, 
            fm16_50: res.data.acf.fm16_50, 
            fm51_499: res.data.acf.fm51_499, 
            xps_2x100mm: res.data.acf.xps_2x100mm, 
            drendukur: res.data.acf.drendukur, 
            malarfarg: res.data.acf.malarfarg, 
            ull_3_log: res.data.acf.ull_3_log, 
            rakasperra: res.data.acf.rakasperra, 
            torf_2_log: res.data.acf.torf_2_log, 
            drendukur_torf: res.data.acf.drendukur_torf,
            utskyring_1: res.data.acf.utskyring_1,
            utskyring_2: res.data.acf.utskyring_2,
            utskyring_3: res.data.acf.utskyring_3,
            utskyring_4: res.data.acf.utskyring_4,
            utskyring_5: res.data.acf.utskyring_5,
            utskyring_6: res.data.acf.utskyring_6,
            utskyring_7: res.data.acf.utskyring_7,
            verkbyrjun: res.data.acf.verkbyrjun
        }))
        .catch(err => console.log(err))
    }

    onResize () {
        // Obviously you can format your data object however you want
        window.parent.postMessage({
            type: 'resize',
            height: document.body.scrollHeight,
        }, '*');
    }

    onSubmit (event) {
        event.preventDefault();
        if(event.keyCode === 13) { 
            console.log('Enter key pressed')
        }
    }

    handleValidation (event) {
        event.preventDefault();
        console.log('submit');
        alert("dsffds");
        return false;
    }

    onSubmittoaerver (event) {
        event.preventDefault();
        if(event.keyCode === 13) { 
            console.log('Enter key pressed')
        }
    }

    handleKeyDown(event) {
        event.preventDefault();
        if(event.keyCode === 13) { 
            console.log('Enter key pressed')
      }
    }

    handleKeyPress = (event) => {
        event.preventDefault();
        if(event.key === 'Enter'){
          console.log('enter press here! ')
        }
    }

    handleChange = event => {
        const {name, value} = event.target
        this.setState({
            [name]: value
        })    
    }

    _fatibod = () => {
        let currentStep = this.state.currentStep
        currentStep = 8
        console.log(currentStep)
        this.setState({
            currentStep: currentStep
        })
    }

    _next = () => {
        let currentStep = this.state.currentStep
        let einangrun = this.state.einangrun;
        let kantur = this.state.kantur;
        let einangruntegund = this.state.einangruntegund;

        currentStep = currentStep >= 8? 9: currentStep + 1
        
        if(currentStep == 4) {
            if (kantur == "ankanti") {
                currentStep=7;
            } else {
                currentStep=5;
            }

        }

        if(currentStep == 5) {
            //if (einangrun == 0) {
            //    currentStep=6;
            //}
        } 
        
        if(currentStep == 6) {
            if (einangruntegund == "xps") {
                currentStep=7;
            }
        }

        
        this.setState({
            currentStep: currentStep
        })
    }

    _prev = () => {
        let currentStep = this.state.currentStep
        currentStep = currentStep <= 1? 1: currentStep - 1
        this.setState({ currentStep: currentStep })
    }

    previousButton() {
        let currentStep = this.state.currentStep;

        if(currentStep !==1){
            return (
                <button className="btn btn-secondary" type="button" onClick={this._prev}>Til baka</button>
            )
        }
        return null;
    }

    nextButton() {
        let currentStep = this.state.currentStep;
        if(currentStep < 7){
            return (
                <button className="btn btn-primary float-right" type="button" onClick={this._next}>Áfram</button>
            )
        }
        return null;
    }

    faformlegttilbodButton() {
        let currentStep = this.state.currentStep;
        if(currentStep == 7){
            return (
                <div>
                <div className="fatilbodtakki"><button className="btn btn-primary fatilbod" type="button" onClick={this._next}>Fá formlegt tilboð</button></div>
                <div className="utskyring"><div dangerouslySetInnerHTML={{__html: this.state.utskyring_7}} /></div>
                </div>
            )
        }
        return null;
    }

    render() {
        return (
            <React.Fragment>
            <div className="wrapperReikn">
                <h2 className='validreikn'>Þakkpappi <a href="/val" className='backhome'>Til baka á forsíðu</a></h2>
                <form onSubmit={this.handleSubmit} onSubmit={this.onSubmit}>
                    <Step1 currentStep={this.state.currentStep} handleChange={this.handleChange} utskyring={this.state.utskyring_1}  flatarmal={this.state.flatarmal} />
                    <Step2 currentStep={this.state.currentStep} handleChange={this.handleChange} utskyring={this.state.utskyring_2} duk={this.state.duk} einangruntegund={this.state.einangruntegund} einangrun={this.state.einangrun} kantur={this.state.kantur} tegund={this.state.tegund} flatarmal={this.state.flatarmal} />
                    <Step3 currentStep={this.state.currentStep} handleChange={this.handleChange} utskyring={this.state.utskyring_3} duk={this.state.duk} einangruntegund={this.state.einangruntegund} einangrun={this.state.einangrun} kantur={this.state.kantur} tegund={this.state.tegund} flatarmal={this.state.flatarmal} />
                    <Step4 currentStep={this.state.currentStep} handleChange={this.handleChange} utskyring={this.state.utskyring_4} duk={this.state.duk} einangruntegund={this.state.einangruntegund} einangrun={this.state.einangrun} kantur={this.state.kantur} tegund={this.state.tegund} flatarmal={this.state.flatarmal} />
                    <Step5 currentStep={this.state.currentStep} handleChange={this.handleChange} utskyring={this.state.utskyring_5} duk={this.state.duk} einangruntegund={this.state.einangruntegund} einangrun={this.state.einangrun} kantur={this.state.kantur} tegund={this.state.tegund} flatarmal={this.state.flatarmal} />
                    <Step6 currentStep={this.state.currentStep} handleChange={this.handleChange} utskyring={this.state.utskyring_6} duk={this.state.duk} einangruntegund={this.state.einangruntegund} einangrun={this.state.einangrun} kantur={this.state.kantur} tegund={this.state.tegund} flatarmal={this.state.flatarmal} />
                    <Step7 currentStep={this.state.currentStep}
                        verkbyrjun={this.state.verkbyrjun}
                        utskyring={this.state.utskyring_7}
                        handleChange={this.handleChange}
                        duk={this.state.duk}
                        einangruntegund={this.state.einangruntegund}
                        einangrun={this.state.einangrun}
                        kantur={this.state.kantur}
                        tegund={this.state.tegund}
                        flatarmal={this.state.flatarmal}
                        fm1_15={this.state.fm1_15}
                        fm16_50={this.state.fm16_50}
                        fm51_499={this.state.fm51_499}
                        xps_2x100mm={this.state.xps_2x100mm}
                        drendukur={this.state.drendukur}
                        malarfarg={this.state.malarfarg}
                        ull_3_log={this.state.ull_3_log}
                        rakasperra={this.state.rakasperra}
                        torf_2_log={this.state.torf_2_log}
                        drendukur_torf={this.state.drendukur_torf}
                    />
                    <Step8 currentStep={this.state.currentStep}
                        utskyring={this.state.utskyring_7}
                        handleChange={this.handleChange}
                        verkbyrjun={this.state.verkbyrjun}
                        duk={this.state.duk}
                        einangruntegund={this.state.einangruntegund}
                        einangrun={this.state.einangrun}
                        kantur={this.state.kantur}
                        tegund={this.state.tegund}
                        flatarmal={this.state.flatarmal}
                        fm1_15={this.state.fm1_15}
                        fm16_50={this.state.fm16_50}
                        fm51_499={this.state.fm51_499}
                        xps_2x100mm={this.state.xps_2x100mm}
                        drendukur={this.state.drendukur}
                        malarfarg={this.state.malarfarg}
                        ull_3_log={this.state.ull_3_log}
                        rakasperra={this.state.rakasperra}
                        torf_2_log={this.state.torf_2_log}
                        drendukur_torf={this.state.drendukur_torf}
                    />
                    {this.faformlegttilbodButton()}
                    {this.previousButton()}
                    {this.nextButton()}
                </form>
            </div>
            </React.Fragment>
        );
    }
}

function Step1(props) {
    if (props.currentStep !== 1) {
        return null
    } 
    var rows = [], i = 19, len = 600;
        while (++i <= len) rows.push(i);
    return(
        <div className="form-group">
            <div className={"thak "}></div>
            <h4 className="fm">Hvað er flöturinn í m<sup>2</sup>?</h4>
            <select id="flatarmal" name="flatarmal" value={props.flatarmal} onChange={props.handleChange} onKeyPress={props.handleKeyPress} onKeyDown={props.handleKeyDown}>
                {rows.map(row =>
                    <option key={row} >{row}</option>
                )}
            </select>
            <div className="utskyring"></div>
        </div>
    );
}

function Step2(props) {
    if (props.currentStep !== 2) {
        return null
    } 
    return(
        <div className="form-group">
            <div className={"thak "+props.tegund}></div>
            <h4 className="medutskyringu">Úr hverju er þakið þitt?</h4>
            <div className="utskyring top"><div dangerouslySetInnerHTML={{__html: props.utskyring}} /></div>
            <div className="toggle">
                <input type="radio" name="tegund" value="steypa" id="steypa" onChange={props.handleChange}/>
                <label htmlFor="steypa">Steypu</label>
                <input type="radio" name="tegund" value="timbur" id="timbur"  onChange={props.handleChange}/>
                <label htmlFor="timbur">Timbri</label>
                <input type="radio" name="tegund" value="stal" id="stal"  onChange={props.handleChange}/>
                <label htmlFor="stal">Stáli</label>
            </div>
            
        </div>
    );
}

function Step3(props) {
    if (props.currentStep !== 3) {
        return null
    } 
    return(
        <React.Fragment>
            <div className="form-group">
                <div className={"thak "+props.tegund + " " + props.kantur}></div>
                <h4 className="medutskyringu">Er þakið með eða á kanta?</h4>
                <div className="utskyring top"><div dangerouslySetInnerHTML={{__html: props.utskyring}} /></div>
                <div className="toggle">
                    <input type="radio" name="kantur" value="medkanti" id="jakantur" onChange={props.handleChange}/>
                    <label htmlFor="jakantur">Með</label>
                    <input type="radio" name="kantur" value="ankanti" id="neikantur" onChange={props.handleChange}/>
                    <label htmlFor="neikantur">Án</label>
                </div>
            </div>
        </React.Fragment>
    );
}

function Step4(props) {
    if (props.currentStep !== 4) {
        return null
    }
    return(
        <React.Fragment>
            <div className="form-group">
                <div className={"thak "+props.tegund + " " + props.kantur}>
                    <div className="thakpappix2"><span>Þakpappi</span></div>
                </div>
                <h4 className="medutskyringu">Viltu einangrun á þakið?</h4>
                <div className="utskyring top"><div dangerouslySetInnerHTML={{__html: props.utskyring}} /></div>
                <div className="toggle">
                    <input type="radio" name="einangrun" value="1" id="jaeinangrun" onChange={props.handleChange}/>
                    <label htmlFor="jaeinangrun">Já</label>
                    <input type="radio" name="einangrun" value="0" id="neieinangrun" onChange={props.handleChange}/>
                    <label htmlFor="neieinangrun">Nei</label>
                </div>
            </div>
        </React.Fragment>
    );
}

function Step5(props) {
    if (props.currentStep !== 5) {
        return null
    }
    return(
        <React.Fragment>
            <div className="form-group">
                <div className={"thak "+props.tegund + " " + props.kantur}>
                    <div className={"thakpappix2 m_" + props.einangruntegund}><span>Þakpappi</span></div>
                    <div className={props.einangruntegund}><span className="ull_n">Ull</span><span className="ull_nr">Rakasperra</span><span className="xps_n">Xps</span><span className="xps_m">Möl</span><span className="xps_d_line"></span><span className="xps_d">Drendúkur</span></div>
                </div>
                <h4 className="medutskyringu">Má bjóða þér einangrun?</h4>
                <div className="utskyring top"><div dangerouslySetInnerHTML={{__html: props.utskyring}} /></div>
                <div className="toggle hverningein">
                    <input type="radio" name="einangruntegund" value="ull" id="ull" onChange={props.handleChange}/>
                    <label htmlFor="ull" >&nbsp;&nbsp;&nbsp;Ull&nbsp;&nbsp;&nbsp; <div className="aukalysing">(2x100mm/1x28mm)</div></label>
                    <input type="radio" name="einangruntegund" value="xps" id="xps" onChange={props.handleChange}/>
                    <label htmlFor="xps">XPS <div className="aukalysing">(2x100mm)</div></label>
                    <input type="radio" name="einangruntegund" value="engaeniangrun" id="engaeniangrun" onChange={props.handleChange}/>
                    <label htmlFor="engaeniangrun">Enga einangrun</label>
                </div>
            </div>
        </React.Fragment>
    );
}



function Step6(props) {
    if (props.currentStep !== 6) {
        return null
    }
    return(
        <React.Fragment>
            <div className="form-group">
                <div className={"thak "+props.tegund + " " + props.kantur}>
                    <div className={"thakpappix2 m_" + props.einangruntegund + " " + props.duk}><span className="torfdren_n">Torf og Drendúkur</span><span>Þakpappi</span></div>
                    <div className={props.einangruntegund}><span className="ull_n">Ull</span><span className="ull_nr">Rakasperra</span><span className="xps_n">Xps</span><span className="xps_m">Möl</span><span className="xps_d_line"></span><span className="xps_d">Drendúkur</span></div>
                </div>
                <h4 className="medutskyringu">Viltu Torf og Drendúk? </h4>
                <div className="utskyring top"><div dangerouslySetInnerHTML={{__html: props.utskyring}} /></div>
                <div className="toggle">
                    <input type="radio" name="duk" value="ja" id="jaduk" onChange={props.handleChange}/>
                    <label htmlFor="jaduk">Já</label> 
                    <input type="radio" name="duk" value="nei" id="neiduk" onChange={props.handleChange}/>
                    <label htmlFor="neiduk">Nei</label>
                </div>
            </div>
        </React.Fragment>
    );
}

function Step7(props) {
    if (props.currentStep !== 7) {
        return null
    } 
    let verdidmitt = 0;
    let v_einangrun = 0;
    let v_ull = 0;
    let v_torf = 0;
    let v_dre = 0;
    let v_flatamal = 0;
    let torf = 0;
    let drendukur = 0;
    let v_einangrun_ull = 0;
    let v_einangrunraki = 0;
    let v_malarfarg = 0;
    let v_xps = 0;
    let verkbyrjun = props.verkbyrjun;

    if (props.flatarmal <= 15) {
        verdidmitt = props.fm1_15;
    } else if (props.flatarmal >= 16 && props.flatarmal <= 50) {
        verdidmitt = props.fm16_50;
    } else {
        verdidmitt = props.fm51_499;
    }

    if (v_einangrun == 0) {
            
    } else {

    }


    if (props.duk === "ja") { 
        torf = props.torf_2_log * props.flatarmal;
        v_dre = props.drendukur_torf * props.flatarmal;
        v_torf = torf + v_dre;
    } 

    if (props.einangruntegund == "ull") { 
        v_einangrun_ull = props.ull_3_log * props.flatarmal;
        v_einangrunraki = props.rakasperra * props.flatarmal;
        v_einangrun = v_einangrun_ull + v_einangrunraki;
        v_xps = 0;
    } else if (props.einangruntegund === "xps") {
        v_malarfarg = props.malarfarg * props.flatarmal;
        drendukur = props.drendukur_torf * props.flatarmal;
        v_xps = props.xps_2x100mm * props.flatarmal;
        v_einangrun = v_xps + drendukur + v_malarfarg;
    }

    let thaktegund = "";
    if (props.tegund == "timbur") {
        thaktegund = "Timburþak";
    } else if (props.tegund == "stal") {
        thaktegund = "Stálþak";
    } else {
        thaktegund = "Steypt þak";
    }

    let einagrunvalin = "";

    if (props.einangruntegund === "ull") {
        einagrunvalin = "Harðpressaða ull og rakasperru <br/>";
    } else if (props.einangruntegund === "xps") {
        einagrunvalin = "XPS einangrun drendúk og Malarfarg <br/>";    
    } else {
        einagrunvalin = "";     
    }
    let dukvalin = "";
    if (props.duk === "ja") {
        dukvalin = "Torf og Drendúk";
    } else {
        dukvalin = "";    
    }

    v_flatamal = props.flatarmal * verdidmitt;
    const reiknaverd = v_flatamal + v_einangrun + v_ull + v_torf;
    return(
        <React.Fragment>
            <div>
                <h3>Þú hefur valið</h3>
                Flatarmál: {props.flatarmal} m2<br/>
                {thaktegund} með Tvöfalda pappalögn<br/>
                <div dangerouslySetInnerHTML={{__html: einagrunvalin}}></div>
                {dukvalin}
                <div className={"thak "+props.tegund + " " + props.kantur}>
                    <div className={"thakpappix2 m_" + props.einangruntegund + " " + props.duk}><span className="torfdren_n">Torf og Drendúkur</span><span>Þakpappi</span></div>
                    <div className={props.einangruntegund}><span className="ull_n">Ull</span><span className="ull_nr">Rakasperra</span><span className="xps_n">Xps</span><span className="xps_m">Möl</span><span className="xps_d_line"></span><span className="xps_d">Drendúkur</span></div>
                </div>
                <h4 className="verd">Verðáætlun:</h4>
                <NumberFormat value={reiknaverd} className="verd" displayType={'text'} thousandSeparator="." decimalSeparator=","
                    renderText={(value, props) => <div {...props}>{value} kr.</div>}
                />
                <strong>Áætluð verkbyrjun</strong><br/>
                    {verkbyrjun}<br/><br/>
            </div>
        </React.Fragment>
    );
}

function Step8(props) {
    if (props.currentStep !== 8) {
        return null
    } 
    let verdidmitt = 0;
    let v_einangrun = 0;
    let v_ull = 0;
    let v_torf = 0;
    let v_dre = 0;
    let v_flatamal = 0;
    let torf = 0;
    let drendukur = 0;
    let v_einangrun_ull = 0;
    let v_einangrunraki = 0;
    let v_malarfarg = 0;
    let v_xps = 0;
    let flatarmal = 0;
    let duk = 0;

    if (props.flatarmal <= 15) {
        verdidmitt = props.fm1_15;
    } else if (props.flatarmal >= 16 && props.flatarmal <= 50) {
        verdidmitt = props.fm16_50;
    } else {
        verdidmitt = props.fm51_499;
    }

    flatarmal = props.flatarmal;

    if (props.duk === "ja") { 
        torf = props.torf_2_log * props.flatarmal;
        v_dre = props.drendukur_torf * props.flatarmal;
        v_torf = torf + v_dre;
        duk = 1;
    } 


    if (props.einangruntegund == "ull") { 
        v_einangrun_ull = props.ull_3_log * props.flatarmal;
        v_einangrunraki = props.rakasperra * props.flatarmal;
        v_einangrun = v_einangrun_ull + v_einangrunraki;
        v_xps = 0;
    } else if (props.einangruntegund === "xps") {
        v_malarfarg = props.malarfarg * props.flatarmal;
        drendukur = props.drendukur_torf * props.flatarmal;
        v_xps = props.xps_2x100mm * props.flatarmal;
        v_einangrun = v_xps + drendukur + v_malarfarg;
    }
    
    let thaktegund = "";
    if (props.tegund == "timbur") {
        thaktegund = "Timburþak";
    } else if (props.tegund == "stal") {
        thaktegund = "Stálþak";
    } else {
        thaktegund = "Steypt þak";
    }

    let einagrunvalin = "";

    if (props.einangruntegund === "ull") {
        einagrunvalin = "Harðpressaða ull og rakasperru";
    } else if (props.einangruntegund === "xps") {
        einagrunvalin = "XPS einangrun drendúk og Malarfarg ";    
    } else {
        einagrunvalin = "";     
    }
    let dukvalin = "";
    if (props.duk === "ja") {
        dukvalin = "Torf og Drendúk";
    } else {
        dukvalin = "";    
    }

    v_flatamal = props.flatarmal * verdidmitt;
    const reiknaverd = v_flatamal + v_einangrun + v_torf;

    let textiadsendamedp = "";
    textiadsendamedp = textiadsendamedp + "<p>Flatarmál:" + props.flatarmal + "m2</p>";
    textiadsendamedp = textiadsendamedp + "<p>" + thaktegund + " með Tvöfalda pappalögn </p>";
    textiadsendamedp = textiadsendamedp + "<p>" + dukvalin + "</p>";
    textiadsendamedp = textiadsendamedp + "<p>" + einagrunvalin + "</p>";

    let textiadsenda = "";
    textiadsenda = textiadsenda + "Flatarmál:" + props.flatarmal + "m2 \n";
    textiadsenda = textiadsenda + thaktegund + " með Tvöfalda pappalögn \n";
    textiadsenda = textiadsenda + dukvalin + "\n";
    textiadsenda = textiadsenda + einagrunvalin + "\n" ;

    return(
        <React.Fragment>
            <div className="wrapperReikn">
                <h3>Fá formlegt tilboð</h3>
                <form method="post" action="https://content.thakco.is/ajax/createapost.php" onSubmit={props.handleValidation}>

                    <input type="text" name="Nafn" className="inputform" placeholder="Nafn" required />
                    <input type="email" name="Netfang" className="inputform" placeholder="Netfang" required />
                    <input type="text" name="Simi" className="inputform" placeholder="Símanúmer" required />
                    <input type="text" name="kennitala" className="inputform" placeholder="Kennitala" required />
                    <input type="text" name="heimilisfang" className="inputform" placeholder="Heimilisfang" required />
                    <input type="text" name="postcode" className="inputform postcode" placeholder="Póstnr." required />
                    <input type="text" name="stadur" className="inputform stadur" placeholder="Bær/Borg" required />

                    <textarea name="athugasemdir" className="athugasemdir" placeholder="Athugasemdir"></textarea>

                    <input type="hidden" name="heildarverd" value={reiknaverd} />
                    <input type="hidden" name="tegund" value={props.tegund} />
                    <input type="hidden" name="einangruntegund" value={props.einangruntegund} />

                    <input type="hidden" name="duk" value={duk} />
                    <input type="hidden" name="content" value={textiadsenda} />
                    <input type="hidden" name="contentemial" value={textiadsendamedp} />
                    <input type="hidden" name="einagrunvalin" value={einagrunvalin} />
                    <input type="hidden" name="flatarmal" value={flatarmal} />
                    <input type="hidden" name="dukvalin" value={dukvalin} />

                    <input type="hidden" name="verdidmitt" value={verdidmitt} />
                    <input type="hidden" name="v_torf" value={v_torf} />
                    <input type="hidden" name="v_einangrun" value={v_einangrun} />
                    <input type="hidden" name="v_flatamal" value={v_flatamal} />
                    
                    <input type="submit" value="Senda" className="next" />
                </form>
            </div>
        </React.Fragment>
    );
}

export default Reiknadu;