import React, { Component } from 'react';
import axios from 'axios';
import NumberFormat from 'react-number-format';

export class Parket extends Component {

    constructor(props) {
        super(props)
        this.state = {
            currentStep: 1,
            flatarmal:  10,
            rifogforgun: "0",
            eldavel: "0",
            vaskur: "1",
            holdur: "0",
            samsetning: "1",
            tegun:"0",
            liming:"0",
            logn:"0",
            fiskibeina:0,
            uppsetning: "0",
            heildverd: 0,
            metraverd: 15000,
            rifogforgunverd: 17500,
            staerdskiptirmali: "",
            samsetingaverd: 0,
            uppsetningaverd: 0,
            holdurverd: 0,
            vaskurverd: 0,
            eldavelverd: 0,
            utskyring_1: '',
            utskyring_2: 'Hér vantar texta um hversu frábært það er að fá rif og förgun.',
            utskyring_3: '',
            utskyring_4: '',
            utskyring_5: '',
            utskyring_6: '',
            utskyring_7: '',
            utskyring_8: '',
            verkbyrjun:'',
            vidarparketanundirlags:0,
            hardparketanundirlags:0,
            hardparket_nidurlimt:0,
            vinilparketanundirlags:0,
            rifogforgunolimt:0,
            rifogforgunnidurlimt:0,
            undirlagteppi:0,
            undirlaggumidukur:0,
        }
    }

    componentDidMount () {
        this.onResize();
        (new ResizeObserver(_ => this.onResize())).observe(document.body);

        axios.get('https://content.thakco.is/wp-json/wp/v2/pages/1140')
        .then(res => this.setState({
            utskyring_1: res.data.acf.utskyring_1,
            utskyring_2: res.data.acf.utskyring_2,
            utskyring_3: res.data.acf.utskyring_3,
            utskyring_4: res.data.acf.utskyring_4,
            utskyring_5: res.data.acf.utskyring_5,
            utskyring_6: res.data.acf.utskyring_6,
            utskyring_7: res.data.acf.utskyring_7,
            utskyring_8: res.data.acf.utskyring_8,
            verkbyrjun: res.data.acf.verkbyrjun,
            vidarparketanundirlags:res.data.acf.vidarparketanundirlags,
            hardparketanundirlags:res.data.acf.hardparketanundirlags,
            hardparket_nidurlimt:res.data.acf.hardparket_nidurlimt,
            vinilparketanundirlags:res.data.acf.vinilparketanundirlags,
            rifogforgunolimt:res.data.acf.rifogforgunolimt,
            rifogforgunnidurlimt:res.data.acf.rifogforgunnidurlimt,
            undirlagteppi:res.data.acf.undirlagteppi,
            undirlaggumidukur:res.data.acf.undirlaggumidukur,
            fiskibeina:res.data.acf.fiskibeina,
        }))
        .catch(err => console.log(err))

    }

    onResize () {
        // Obviously you can format your data object however you want
        window.parent.postMessage({
            type: 'resize',
            height: document.body.scrollHeight,
        }, '*');
    }

    handleChange = event => {
        const {name, value} = event.target
        this.setState({
            [name]: value
        })
    }

    handleFMChange = event => {
        let { value, min, max } = event.target;
        if (parseInt(value) < 10) {
            this.setState({"staerdskiptirmali": "Má ekki vera undir " + min });
        } else if (parseInt(value) > 500) {
            this.setState({"staerdskiptirmali": "Má ekki vera meira en " + max });
        } else {
            this.setState({"staerdskiptirmali": ""});
        }

        this.setState({"flatarmal": value });
    }

    _fatibod = () => {
        let currentStep = this.state.currentStep
        currentStep = 7
        this.setState({
            currentStep: currentStep
        })
    }

    _next = () => {
        let flatarmal = this.state.flatarmal
        let currentStep = this.state.currentStep
        let tegundin = this.state.tegun

        if (flatarmal >= 10 && flatarmal <= 500) {

            if (currentStep === 4 ) {
                if (tegundin !== "1" ) {
                    currentStep = currentStep >= 7? 8: currentStep + 1
                } else {
                    currentStep = currentStep >= 7? 8: currentStep + 2
                }
            } else {
                currentStep = currentStep >= 7? 8: currentStep + 1
            }
            this.setState({
                currentStep: currentStep
            })
        }
    }

    _prev = () => {
        let currentStep = this.state.currentStep
        currentStep = currentStep <= 1? 1: currentStep - 1
        this.setState({
            currentStep: currentStep
        })
    }

    previousButton() {
        let currentStep = this.state.currentStep;
        if(currentStep !==1){
            return (
            <button className="btn btn-secondary" type="button" onClick={this._prev}>Til baka</button>
            )
        }
        return null;
    }

    nextButton() {
        let currentStep = this.state.currentStep;
        if(currentStep < 7){
            return (
                <button className="btn btn-primary float-right" type="button" onClick={this._next}>Áfram</button>
            )
        }
        return null;
    }

    faformlegttilbodButton() {
        let currentStep = this.state.currentStep;
        if(currentStep === 7){
            return (
                <div>
                    <div className="fatilbodtakki"><button className="btn btn-primary fatilbod" type="button" onClick={this._next}>Fá formlegt tilboð</button></div>
                    <div className="utskyring"><div dangerouslySetInnerHTML={{__html: this.state.utskyring_9}} /></div>
                </div>
            )
        }
        return null;
    }

    render() {
        return (
            <div className="wrapperReikn">
                <h2 className='validreikn'>Parket <a href="/val" className='backhome'>Til baka á forsíðu</a></h2>
                <form onSubmit={this.handleSubmit}>
                    <Step1 currentStep={this.state.currentStep} handleFMChange={this.handleFMChange} staerdskiptirmali={this.state.staerdskiptirmali} handleChange={this.handleChange} logn={this.state.logn} liming={this.state.liming} vaskur={this.state.vaskur} samsetning={this.state.samsetning} rifogforgun={this.state.rifogforgun} tegun={this.state.tegun} flatarmal={this.state.flatarmal} utskyring={this.state.utskyring_1}   metraverd={this.state.metraverd} rifogforgunverd={this.state.rifogforgunverd} samsetingaverd={this.state.samsetingaverd} uppsetningaverd={this.state.uppsetningaverd} holdurverd={this.state.holdurverd} vaskurverd={this.state.vaskurverd} eldavelverd={this.state.eldavelverd} /> 
                    <Step2 currentStep={this.state.currentStep} handleChange={this.handleChange} logn={this.state.logn} liming={this.state.liming} tegun={this.state.tegun} flatarmal={this.state.flatarmal} uppsetning={this.state.uppsetning} rifogforgun={this.state.rifogforgun} utskyring={this.state.utskyring_2} eldavel={this.state.eldavel} vaskur={this.state.vaskur} holdur={this.state.holdur} samsetning={this.state.holdur} />
                    <Step3 currentStep={this.state.currentStep} handleChange={this.handleChange} logn={this.state.logn} liming={this.state.liming} tegun={this.state.tegun} flatarmal={this.state.flatarmal} uppsetning={this.state.uppsetning} rifogforgun={this.state.rifogforgun} utskyring={this.state.utskyring_3} eldavel={this.state.eldavel} vaskur={this.state.vaskur} holdur={this.state.holdur} samsetning={this.state.holdur} />
                    <Step4 currentStep={this.state.currentStep} handleChange={this.handleChange} logn={this.state.logn} liming={this.state.liming} tegun={this.state.tegun} flatarmal={this.state.flatarmal} uppsetning={this.state.uppsetning} rifogforgun={this.state.rifogforgun} utskyring={this.state.utskyring_8} eldavel={this.state.eldavel} vaskur={this.state.vaskur} holdur={this.state.holdur} samsetning={this.state.holdur} />
                    <Step5 currentStep={this.state.currentStep} handleChange={this.handleChange} logn={this.state.logn} liming={this.state.liming} tegun={this.state.tegun} flatarmal={this.state.flatarmal} uppsetning={this.state.uppsetning} rifogforgun={this.state.rifogforgun} utskyring={this.state.utskyring_4} eldavel={this.state.eldavel} vaskur={this.state.vaskur} holdur={this.state.holdur} samsetning={this.state.holdur} />
                    <Step6 currentStep={this.state.currentStep} handleChange={this.handleChange} logn={this.state.logn} liming={this.state.liming} tegun={this.state.tegun} flatarmal={this.state.flatarmal} uppsetning={this.state.uppsetning} rifogforgun={this.state.rifogforgun} utskyring={this.state.utskyring_7} eldavel={this.state.eldavel} vaskur={this.state.vaskur} holdur={this.state.holdur} samsetning={this.state.holdur} metraverd={this.state.metraverd} rifogforgunverd={this.state.rifogforgunverd} samsetingaverd={this.state.samsetingaverd} uppsetningaverd={this.state.uppsetningaverd} holdurverd={this.state.holdurverd} vaskurverd={this.state.vaskurverd} eldavelverd={this.state.eldavelverd} vidarparketanundirlags={this.state.vidarparketanundirlags} hardparketanundirlags={this.state.hardparketanundirlags} hardparket_nidurlimt={this.state.hardparket_nidurlimt} vinilparketanundirlags={this.state.vinilparketanundirlags} rifogforgunolimt={this.state.rifogforgunolimt} rifogforgunnidurlimt={this.state.rifogforgunnidurlimt} undirlagteppi={this.state.undirlagteppi} undirlaggumidukur={this.state.undirlaggumidukur} />
                    <Step7 currentStep={this.state.currentStep} _next={this._next} handleChange={this.handleChange} fiskibeina={this.state.fiskibeina} logn={this.state.logn} liming={this.state.liming} tegun={this.state.tegun} flatarmal={this.state.flatarmal} uppsetning={this.state.uppsetning} rifogforgun={this.state.rifogforgun} utskyring={this.state.utskyring_9} eldavel={this.state.eldavel} vaskur={this.state.vaskur} holdur={this.state.holdur} samsetning={this.state.holdur} metraverd={this.state.metraverd} rifogforgunverd={this.state.rifogforgunverd} samsetingaverd={this.state.samsetingaverd} uppsetningaverd={this.state.uppsetningaverd} holdurverd={this.state.holdurverd} vaskurverd={this.state.vaskurverd} eldavelverd={this.state.eldavelverd} vidarparketanundirlags={this.state.vidarparketanundirlags} hardparketanundirlags={this.state.hardparketanundirlags} hardparket_nidurlimt={this.state.hardparket_nidurlimt} vinilparketanundirlags={this.state.vinilparketanundirlags} rifogforgunolimt={this.state.rifogforgunolimt} rifogforgunnidurlimt={this.state.rifogforgunnidurlimt} undirlagteppi={this.state.undirlagteppi} undirlaggumidukur={this.state.undirlaggumidukur} />
                    <Step8 currentStep={this.state.currentStep} handleChange={this.handleChange} fiskibeina={this.state.fiskibeina} logn={this.state.logn} liming={this.state.liming} tegun={this.state.tegun} flatarmal={this.state.flatarmal} uppsetning={this.state.uppsetning} rifogforgun={this.state.rifogforgun} utskyring={this.state.utskyring_9} eldavel={this.state.eldavel} vaskur={this.state.vaskur} holdur={this.state.holdur} samsetning={this.state.holdur} metraverd={this.state.metraverd} rifogforgunverd={this.state.rifogforgunverd} samsetingaverd={this.state.samsetingaverd} uppsetningaverd={this.state.uppsetningaverd} holdurverd={this.state.holdurverd} vaskurverd={this.state.vaskurverd} eldavelverd={this.state.eldavelverd} vidarparketanundirlags={this.state.vidarparketanundirlags} hardparketanundirlags={this.state.hardparketanundirlags} hardparket_nidurlimt={this.state.hardparket_nidurlimt} vinilparketanundirlags={this.state.vinilparketanundirlags} rifogforgunolimt={this.state.rifogforgunolimt} rifogforgunnidurlimt={this.state.rifogforgunnidurlimt} undirlagteppi={this.state.undirlagteppi} undirlaggumidukur={this.state.undirlaggumidukur} />
                    {this.previousButton()}
                    {this.nextButton()}
                </form>
            </div>
        );
    }
}

function Step1(props) {
    if (props.currentStep !== 1) {
        return null
    }
    var rows = [], i = 9, len = 500;
    while (++i <= len) rows.push(i);
    return(
        <div className="form-group">
            <div className={'parketflotur logn' + props.logn }>
                <div className={'lognonoff erfisk_' + props.logn }></div>
                <div className={'limingonoff erlim_' + props.liming }></div>
                <div className={'undirlagid erundirlag_' + props.vaskur }></div>
                <div className={'tegundicon tegund_' + props.tegun }></div>
                <div className={'forgunonoff erforgun_' + props.rifogforgun }></div>
                <div className='fme'>{props.flatarmal} m<sup>2</sup></div>
            </div>
            <h4 className='medutskyringu'>Hvað er flöturinn í m<sup>2</sup></h4>
            <div className="utskyring top">{props.utskyring}</div>
            <input value={props.flatarmal} id="flatarmal" name="flatarmal" type="number" onChange={props.handleFMChange} min="10" max="500"/>
            <div className="villa">{props.staerdskiptirmali}</div>
            <div className="utskyring">Minnst: 10, mest 500 m2</div>
        </div>
    );
}

function Step2(props) {
    if (props.currentStep !== 2) {
        return null
    }
    return(
        <div className="form-group">
            <div className={'parketflotur logn' + props.logn }>
                <div className={'lognonoff erfisk_' + props.logn }></div>
                <div className={'limingonoff erlim_' + props.liming }></div>
                <div className={'undirlagid erundirlag_' + props.vaskur }></div>
                <div className={'tegundicon tegund_' + props.tegun }></div>
                <div className={'forgunonoff erforgun_' + props.rifogforgun }></div>
                <div className='fme'>{props.flatarmal} m<sup>2</sup></div>
            </div>
            <h4 className='medutskyringu'>Þarftu rif og förgun?</h4>
            <div className="utskyring top"><div dangerouslySetInnerHTML={{__html: props.utskyring}} /></div>
            <div className="toggle">
                <input type="radio" name="rifogforgun" checked={props.rifogforgun === "1"} value="1" id="tiu" onChange={props.handleChange}/>
                <label htmlFor="tiu" >Já</label>
                <input type="radio" name="rifogforgun" checked={props.rifogforgun === "0"} value="0" id="tuttugu"  onChange={props.handleChange}/>
                <label htmlFor="tuttugu">Nei</label>
            </div>
        </div>
    );
}

function Step3(props) {
    if (props.currentStep !== 3) {
        return null
    }
    return(
        <React.Fragment>
            <div className="form-group">
                <div className={'parketflotur logn' + props.logn }>
                    <div className={'lognonoff erfisk_' + props.logn }></div>
                    <div className={'limingonoff erlim_' + props.liming }></div>
                    <div className={'undirlagid erundirlag_' + props.vaskur }></div>
                    <div className={'tegundicon tegund_' + props.tegun }></div>
                    <div className={'forgunonoff erforgun_' + props.rifogforgun }></div>
                    <div className='fme'>{props.flatarmal} m<sup>2</sup></div>
                </div>
                <h4 className='medutskyringu'>Tegund</h4>
                <div className="utskyring top"><div dangerouslySetInnerHTML={{__html: props.utskyring}} /></div>
                <div className="toggle">
                    <input type="radio" name="tegun" value="0" checked={props.tegun === "0"} id="hardvidar" onChange={props.handleChange}/>
                    <label htmlFor="hardvidar">Harðparket</label>
                    <input type="radio" name="tegun" value="1" checked={props.tegun === "1"} id="vidar" onChange={props.handleChange}/>
                    <label htmlFor="vidar">Viðarparket</label>
                    <input type="radio" name="tegun" value="2" checked={props.tegun === "2"} id="vinil" onChange={props.handleChange}/>
                    <label htmlFor="vinil">Vínylparket</label>
                </div>
            </div>
        </React.Fragment>
    );
}

function Step4(props) {
    if (props.currentStep !== 4) {
        return null
    }
    return(
        <React.Fragment>
            <div className="form-group">
                <div className={'parketflotur logn' + props.logn }>
                    <div className={'lognonoff erfisk_' + props.logn }></div>
                    <div className={'limingonoff erlim_' + props.liming }></div>
                    <div className={'undirlagid erundirlag_' + props.vaskur }></div>
                    <div className={'tegundicon tegund_' + props.tegun }></div>
                    <div className={'forgunonoff erforgun_' + props.rifogforgun }></div>
                    <div className='fme'>{props.flatarmal} m<sup>2</sup></div>
                </div>
                <h4 className='medutskyringu'>Parketlögn?</h4>
                <div className="utskyring top"><div dangerouslySetInnerHTML={{__html: props.utskyring}} /></div>
                <div className="toggle">
                    <input type="radio" name="logn" value="0" checked={props.logn === "0"} id="hefdbundin" onChange={props.handleChange}/>
                    <label htmlFor="hefdbundin">Hefðbundin</label>
                    <input type="radio" name="logn" value="1" checked={props.logn === "1"} id="fiskibeina" onChange={props.handleChange}/>
                    <label htmlFor="fiskibeina">Fiskibeina</label>
                </div>
            </div>
        </React.Fragment>
    );
}

function Step5(props) {
    if (props.currentStep !== 5) {
        return null
    }
    return(
        <React.Fragment>
            <div className="form-group">
                <div className={'parketflotur logn' + props.logn }>
                    <div className={'lognonoff erfisk_' + props.logn }></div>
                    <div className={'limingonoff erlim_' + props.liming }></div>
                    <div className={'undirlagid erundirlag_' + props.vaskur }></div>
                    <div className={'tegundicon tegund_' + props.tegun }></div>
                    <div className={'forgunonoff erforgun_' + props.rifogforgun }></div>
                    <div className='fme'>{props.flatarmal} m<sup>2</sup></div>
                </div>
                <h4 className='medutskyringu'>Líming?</h4>
                <div className="utskyring top"><div dangerouslySetInnerHTML={{__html: props.utskyring}} /></div>
                <div className="toggle">
                    <input type="radio" name="liming" value="1" checked={props.liming === "1"} id="nidurlimt" onChange={props.handleChange}/>
                    <label htmlFor="nidurlimt">Niðurlímt</label>
                    <input type="radio" name="liming" value="0" checked={props.liming === "0"} id="onidurlimt" onChange={props.handleChange}/>
                    <label htmlFor="onidurlimt">Óniðurlímt</label>
                </div>
            </div>
        </React.Fragment>
    );
}


function Step6(props) {
    if (props.currentStep !== 6) {
        return null
    }
    var rowsStk = [], istk = -1, lenstk = 20;
    while (++istk <= lenstk) rowsStk.push(istk);
    return(
        <React.Fragment>
            <div className="form-group">
                <div className={'parketflotur logn' + props.logn }>
                    <div className={'lognonoff erfisk_' + props.logn }></div>
                    <div className={'limingonoff erlim_' + props.liming }></div>
                    <div className={'undirlagid erundirlag_' + props.vaskur }></div>
                    <div className={'tegundicon tegund_' + props.tegun }></div>
                    <div className={'forgunonoff erforgun_' + props.rifogforgun }></div>
                    <div className='fme'>{props.flatarmal} m<sup>2</sup></div>
                </div>
                <h4 className='medutskyringu'>Undirlag</h4>
                <div className="utskyring top"><div dangerouslySetInnerHTML={{__html: props.utskyring}} /></div>
                <div className="toggle">
                    <input type="radio" name="vaskur" value="1" checked={props.vaskur === "1"} id="vaskurn" onChange={props.handleChange}/>
                    <label htmlFor="vaskurn" id="ekkiundirlag">Ekki Undirlag</label>
                    <input type="radio" name="vaskur" value="0" checked={props.vaskur === "0"} id="vaskur" onChange={props.handleChange}/>
                    <label htmlFor="vaskur">Gúmídúkur </label>
                    <input type="radio" name="vaskur" value="2" checked={props.vaskur === "2"} id="vaskuru" onChange={props.handleChange}/>
                    <label htmlFor="vaskuru">Undirlag</label>
                </div>
            </div>
        </React.Fragment>
    );
}

function Step7(props) {
    if (props.currentStep !== 7) {
        return null
    }

    let texti;
    let heildarverdid = 0;
    let verdreikn = 0;

    texti = "";

    if (props.tegun === "0") {
        texti +=  "Harðparket<br/>" ;
        verdreikn = 15000;
        if (props.vaskur === "0") {
            verdreikn = props.hardparketanundirlags;
        }
        if (props.vaskur === "1") {
            verdreikn = props.hardparketanundirlags;
        }
        if (props.vaskur === "2") {
            verdreikn = props.hardparketanundirlags;
        }
        if (props.liming === "1") {
            verdreikn = props.hardparket_nidurlimt;
        }
    }

    if (props.tegun === "1") {
        texti +=  "Viðarparket<br/>" ;
        if (props.vaskur === "0") {
            verdreikn = props.vidarparketanundirlags;
        }
        if (props.vaskur === "1") {
            verdreikn = props.vidarparketanundirlags;
        }
        if (props.vaskur === "2") {
            verdreikn = props.vidarparketanundirlags;
        }
    }

    if (props.tegun === "2") {
        texti +=  "Vínylparket<br/>";
        if (props.vaskur === "0") {
            verdreikn = props.vinilparketanundirlags;
        }
        if (props.vaskur === "1") {
            verdreikn = props.vinilparketanundirlags;
        }
        if (props.vaskur === "2") {
            verdreikn = props.vinilparketanundirlags;
        }
    }

    texti +=  "Heildar flötur: " + props.flatarmal + " m2 <br/>" ;
    heildarverdid = props.flatarmal * verdreikn;

    if (props.logn === "1") {
        heildarverdid += props.flatarmal * props.fiskibeina;
        texti +=  "Fiskibeina parketlögn <br/>";
    } else {
        texti +=  "Hefðbundin parketlögn <br/>";
    }

    if (props.rifogforgun === "1") {
        if (props.liming === "1") {
            heildarverdid += props.flatarmal * props.rifogforgunnidurlimt;
            texti +=  "Rif og förgun <br/>" ;
            texti +=  "Niðurlímt<br/>" ;
        } else {
            heildarverdid += props.flatarmal * props.rifogforgunolimt;
            texti +=  "Rif og förgun<br/>" ;
            texti +=  "Óniðurlímt<br/>" ;
        }
    } else {
        if (props.liming === "1") {
            texti +=  "Niðurlímt<br/>" ;
        } else if (props.liming === "2") {
            texti +=  "Óniðurlímt<br/>" ;
        }
    }

    if (props.vaskur === "0") {
        texti +=  "Gúmídúkur<br/>" ;
        heildarverdid += props.flatarmal * props.undirlaggumidukur;
    }
    if (props.vaskur === "1") {
        texti +=  "Ekki Undirlag<br/>" ;
    }
    if (props.vaskur === "2") {
        texti +=  "Undirlag<br/>" ;
        heildarverdid += props.flatarmal * props.undirlagteppi;
    }

    return(
        <React.Fragment>
            <div className="form-group">
                <div className={'parketflotur logn' + props.logn }>
                    <div className={'lognonoff erfisk_' + props.logn }></div>
                    <div className={'limingonoff erlim_' + props.liming }></div>
                    <div className={'undirlagid erundirlag_' + props.vaskur }></div>
                    <div className={'tegundicon tegund_' + props.tegun }></div>
                    <div className={'forgunonoff erforgun_' + props.rifogforgun }></div>
                    <div className='fme'><span>{props.flatarmal} m<sup>2</sup></span></div>
                </div>
                <h4>Þú hefur valið</h4>
                <div>
                    <div className="fatilbodtakki"><button className="btn btn-primary fatilbod" type="button" onClick={props._next}>Bóka tíma hér</button></div>
                    <div className="utskyring"><div dangerouslySetInnerHTML={{__html: props.utskyring_9}} /></div>
                </div>
                <div dangerouslySetInnerHTML={{__html: texti}}></div>
                <h4 class="verd">Verðáætlun:</h4>
                <NumberFormat value={heildarverdid} className="verd" displayType={'text'} thousandSeparator="." decimalSeparator=","
                    renderText={(value, props) => <div {...props}>{value} kr.</div>}
                />
                <div className="utskyring"></div>
                <p><strong>Þessi verðáætlun er fyrir vinnulið og er með vsk.</strong></p>
            </div>
        </React.Fragment>
    );
}

function Step8(props) {
    if (props.currentStep !== 8) {
        return null
    }

    let texti;
    let heildarverdid = 0;
    let verdreikn = 0;
    let tegundin = "";
    let limingin = "";
    let dukvalin = "";
    let duk = "";
    let logn = "";
    let lognverd = "";
    let errif = "";
    let rifogfverd = 0;

    texti = "";

    if (props.tegun === "0") {
        texti +=  "Harðparket \n" ;
        tegundin +=  "Harðparket";
        verdreikn = 15000;

        if (props.vaskur === "0") {
            verdreikn = props.hardparketanundirlags;
        }
        if (props.vaskur === "1") {
            verdreikn = props.hardparketanundirlags;
        }
        if (props.vaskur === "2") {
            verdreikn = props.hardparketanundirlags;
        }
        if (props.liming === "1") {
            verdreikn = props.hardparket_nidurlimt;
        }
    }

    if (props.tegun === "1") {
        texti +=  "Viðarparket \n" ;
        tegundin +=  "Viðarparket";
        if (props.vaskur === "0") {
            verdreikn = props.vidarparketanundirlags;
        }
        if (props.vaskur === "1") {
            verdreikn = props.vidarparketanundirlags;
        }
        if (props.vaskur === "2") {
            verdreikn = props.vidarparketanundirlags;
        }
    }

    if (props.tegun === "2") {
        texti +=  "Vínylparket  \n";
        tegundin +=  "Vínylparket";
        if (props.vaskur === "0") {
            verdreikn = props.vinilparketanundirlags;
        }
        if (props.vaskur === "1") {
            verdreikn = props.vinilparketanundirlags;
        }
        if (props.vaskur === "2") {
            verdreikn = props.vinilparketanundirlags;
        }
    }

    texti +=  "Heildar flötur: " + props.flatarmal + " m2 \n" ;
    heildarverdid = props.flatarmal * verdreikn;

    if (props.logn === "1") {
        heildarverdid += props.flatarmal * props.fiskibeina;
        texti +=  "Fiskibeina parketlögn \n";
        logn =  "Fiskibeina parketlögn";
        lognverd = props.fiskibeina;
    } else {
        texti +=  "Hefðbundin parketlögn \n";
        logn =  "Hefðbundin parketlögn";
        lognverd = 0;
    }

    if (props.rifogforgun === "1") {
        if (props.liming === "1") {
            heildarverdid += props.flatarmal * props.rifogforgunnidurlimt;
            texti +=  "Rif og förgun \n" ;
            texti +=  "Niðurlímt \n" ;
            limingin +=  "Niðurlímt" ;
            errif = "Rif og förgun";
            rifogfverd = props.rifogforgunnidurlimt;
        } else {
            heildarverdid += props.flatarmal * props.rifogforgunolimt;
            texti +=  "Rif og förgun \n" ;
            texti +=  "Óniðurlímt \n" ;
            limingin +=  "Óniðurlímt" ;
            errif = "Rif og förgun";
            rifogfverd = props.rifogforgunolimt;
        }
    } else {
        errif = "Ekki Rif og förgun";
        rifogfverd = 0;
        if (props.liming === "1") {
            texti +=  "Niðurlímt \n" ;
            limingin +=  "Niðurlímt" ;
        } else if (props.liming === "2") {
            texti +=  "Óniðurlímt \n" ;
            limingin +=  "Óniðurlímt" ;
        }
    }

    if (props.vaskur === "0") {
        texti +=  "Gúmídúkur \n" ;
        heildarverdid += props.flatarmal * props.undirlaggumidukur;
        dukvalin = props.undirlaggumidukur;
        duk = "Gúmídúkur";
    }
    if (props.vaskur === "1") {
        texti +=  "Ekkert Undirlag \n" ;
        dukvalin = 0;
        duk = "Ekkert Undirlag";
    }
    if (props.vaskur === "2") {
        texti +=  "Undirlag \n" ;
        heildarverdid += props.flatarmal * props.undirlagteppi;
        dukvalin = props.undirlagteppi;
        duk = "Undirlag";
    }

    return(
        <React.Fragment>
            <div className="wrapperReikn">
                <h3>Bóka tíma</h3>
                <form method="post" action="https://content.thakco.is/ajax/createapostparket.php" onSubmit={props.handleValidation}>

                    <input type="text" name="Nafn" className="inputform" placeholder="Nafn" required />
                    <input type="email" name="Netfang" className="inputform" placeholder="Netfang" required />
                    <input type="text" name="Simi" className="inputform" placeholder="Símanúmer" required />
                    <input type="text" name="kennitala" className="inputform" placeholder="Kennitala" required />
                    <input type="text" name="heimilisfang" className="inputform" placeholder="Heimilisfang" required />
                    <input type="text" name="postcode" className="inputform postcode" placeholder="Póstnr." required />
                    <input type="text" name="stadur" className="inputform stadur" placeholder="Bær/Borg" required />

                    <textarea name="athugasemdir" className="athugasemdir" placeholder="Athugasemdir"></textarea>

                    <input type="hidden" name="heildarverd" value={heildarverdid} />
                    <input type="hidden" name="verdreikn" value={verdreikn} />
                    <input type="hidden" name="tegund" value={tegundin} />
                    <input type="hidden" name="limingin" value={limingin} />
                    <input type="hidden" name="rifogforgun" value={errif} />
                    <input type="hidden" name="rifogforgunverd" value={rifogfverd} />
                    <input type="hidden" name="content" value={texti} />
                    <input type="hidden" name="metraverd" value={props.metraverd} />
                    <input type="hidden" name="flatarmal" value={props.flatarmal} />
                    <input type="hidden" name="dukvalin" value={dukvalin} />
                    <input type="hidden" name="duk" value={duk} />
                    <input type="hidden" name="logn" value={logn} />
                    <input type="hidden" name="lognverd" value={lognverd} />

                    <input type="submit" value="Senda" className="next" />
                </form>
            </div>
        </React.Fragment>
    );
}


export default Parket;