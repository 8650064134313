import React, { Component } from 'react';
import axios from 'axios';
import NumberFormat from 'react-number-format';

export class Innihurdir extends Component {

    constructor(props) {
        super(props)
        this.state = {
            currentStep: 1,
            fjoldi:  1,
            rifogforgun: "0",
            yfirfeld: "0",
            rennihurd: "0",
            throskuldur: "2",
            tegund:"1",
            liming:"0",
            uppsetning: "0",
            hurdarhunn: 0,
            heildverd: 0,
            tegundverd: 65000,
            rifogforgunverd: 17500,
            hurdarhunnverd: 0,
            fellithroskuldurverd: 0,
            throskuldurverd: 0,
            rennihurdverd: 0,
            yfirfeldverd: 0,
            stk:0,
            b60:0,
            b30:0,
            utskyring_1: '',
            utskyring_2: '',
            utskyring_3: '',
            utskyring_4: '',
            utskyring_5: '',
            utskyring_6: '',
            utskyring_7: '',
            verkbyrjun:'',
        }
    }

    componentDidMount () {
        this.onResize();
        (new ResizeObserver(_ => this.onResize())).observe(document.body);

        axios.get('https://content.thakco.is/wp-json/wp/v2/pages/1211')
        .then(res => this.setState({
            utskyring_1: res.data.acf.utskyring_1,
            utskyring_2: res.data.acf.utskyring_2,
            utskyring_3: res.data.acf.utskyring_3,
            utskyring_4: res.data.acf.utskyring_4,
            utskyring_5: res.data.acf.utskyring_5,
            utskyring_6: res.data.acf.utskyring_6,
            utskyring_7: res.data.acf.utskyring_7,
            verkbyrjun: res.data.acf.verkbyrjun,
            rifogforgunverd: res.data.acf.rif,
            stk: res.data.acf.stk,
            yfirfeldverd: res.data.acf.yfirfeld,
            rennihurdverd: res.data.acf.rennihurd,
            throskuldurverd: res.data.acf.throskuldur,
            fellithroskuldurverd: res.data.acf.fellithroskuldur,
            b60: res.data.acf.b60,
            b30: res.data.acf.b30,
            hurdarhunnverd: res.data.acf.hurdarhunn,
        }))
        .catch(err => console.log(err))

    }

    onResize () {
        // Obviously you can format your data object however you want
        window.parent.postMessage({
            type: 'resize',
            height: document.body.scrollHeight,
        }, '*');
    }

    handleChange = event => {
        const {name, value} = event.target
        this.setState({
            [name]: value
        })
    }

    _fatibod = () => {
        let currentStep = this.state.currentStep
        currentStep = 9
        this.setState({
            currentStep: currentStep
        })
    }

    _next = () => {
        let currentStep = this.state.currentStep
        let tegundin = this.state.tegund

        currentStep = currentStep >= 9? 10: currentStep + 1
        if (currentStep === 4 ) {
            if (tegundin === "5" ) {
                currentStep = currentStep + 1
            }
        }
        this.setState({
            currentStep: currentStep
        })
    }

    _prev = () => {
        let currentStep = this.state.currentStep
        currentStep = currentStep <= 1? 1: currentStep - 1
        this.setState({
            currentStep: currentStep
        })
    }

    previousButton() {
        let currentStep = this.state.currentStep;
        if(currentStep !==1){
            return (
            <button className="btn btn-secondary" type="button" onClick={this._prev}>Til baka</button>
            )
        }
        return null;
    }

    nextButton() {
        let currentStep = this.state.currentStep;
        if(currentStep < 6){
            return (
                <button className="btn btn-primary float-right" type="button" onClick={this._next}>Áfram</button>
            )
        }
        return null;
    }

    faformlegttilbodButton() {
        let currentStep = this.state.currentStep;
        if(currentStep === 6){
            return (
                <div>
                    <div className="fatilbodtakki"><button className="btn btn-primary fatilbod" type="button" onClick={this._next}>Fá formlegt tilboð</button></div>
                    <div className="utskyring"><div dangerouslySetInnerHTML={{__html: this.state.utskyring_8}} /></div>
                </div>
            )
        }
        return null;
    }

    render() {
        return (
            <div className="wrapperReikn">
                <h2 className='validreikn'>Innihurðir <a href="/val" className='backhome'>Til baka á forsíðu</a></h2>
                <form onSubmit={this.handleSubmit}>
                    <Step1 currentStep={this.state.currentStep} handleChange={this.handleChange} fjoldi={this.state.fjoldi} tegund={this.state.tegund} tegundverd={this.state.tegundverd} rifogforgun={this.state.rifogforgun} rifogforgunverd={this.state.rifogforgunverd} yfirfeld={this.state.yfirfeld} yfirfeldverd={this.state.yfirfeldverd} rennihurd={this.state.rennihurd} rennihurdverd={this.state.rennihurdverd} throskuldur={this.state.throskuldur} throskuldurverd={this.state.throskuldurverd} fellithroskuldurverd={this.state.fellithroskuldurverd} hurdarhunn={this.state.hurdarhunn} hurdarhunnverd={this.state.hurdarhunnverd} utskyring={this.state.utskyring_1} />
                    <Step2 currentStep={this.state.currentStep} handleChange={this.handleChange} fjoldi={this.state.fjoldi} tegund={this.state.tegund} tegundverd={this.state.tegundverd} rifogforgun={this.state.rifogforgun} rifogforgunverd={this.state.rifogforgunverd} yfirfeld={this.state.yfirfeld} yfirfeldverd={this.state.yfirfeldverd} rennihurd={this.state.rennihurd} rennihurdverd={this.state.rennihurdverd} throskuldur={this.state.throskuldur} throskuldurverd={this.state.throskuldurverd} fellithroskuldurverd={this.state.fellithroskuldurverd} hurdarhunn={this.state.hurdarhunn} hurdarhunnverd={this.state.hurdarhunnverd} utskyring={this.state.utskyring_2}  />
                    <Step3 currentStep={this.state.currentStep} handleChange={this.handleChange} fjoldi={this.state.fjoldi} tegund={this.state.tegund} tegundverd={this.state.tegundverd} rifogforgun={this.state.rifogforgun} rifogforgunverd={this.state.rifogforgunverd} yfirfeld={this.state.yfirfeld} yfirfeldverd={this.state.yfirfeldverd} rennihurd={this.state.rennihurd} rennihurdverd={this.state.rennihurdverd} throskuldur={this.state.throskuldur} throskuldurverd={this.state.throskuldurverd} fellithroskuldurverd={this.state.fellithroskuldurverd} hurdarhunn={this.state.hurdarhunn} hurdarhunnverd={this.state.hurdarhunnverd} utskyring={this.state.utskyring_3}  />
                    <Step4 currentStep={this.state.currentStep} handleChange={this.handleChange} fjoldi={this.state.fjoldi} tegund={this.state.tegund} tegundverd={this.state.tegundverd} rifogforgun={this.state.rifogforgun} rifogforgunverd={this.state.rifogforgunverd} yfirfeld={this.state.yfirfeld} yfirfeldverd={this.state.yfirfeldverd} rennihurd={this.state.rennihurd} rennihurdverd={this.state.rennihurdverd} throskuldur={this.state.throskuldur} throskuldurverd={this.state.throskuldurverd} fellithroskuldurverd={this.state.fellithroskuldurverd} hurdarhunn={this.state.hurdarhunn} hurdarhunnverd={this.state.hurdarhunnverd} utskyring={this.state.utskyring_4}  />
                    <Step5 currentStep={this.state.currentStep} handleChange={this.handleChange} fjoldi={this.state.fjoldi} tegund={this.state.tegund} tegundverd={this.state.tegundverd} rifogforgun={this.state.rifogforgun} rifogforgunverd={this.state.rifogforgunverd} yfirfeld={this.state.yfirfeld} yfirfeldverd={this.state.yfirfeldverd} rennihurd={this.state.rennihurd} rennihurdverd={this.state.rennihurdverd} throskuldur={this.state.throskuldur} throskuldurverd={this.state.throskuldurverd} fellithroskuldurverd={this.state.fellithroskuldurverd} hurdarhunn={this.state.hurdarhunn} hurdarhunnverd={this.state.hurdarhunnverd} utskyring={this.state.utskyring_5}  />
                    <Step6 currentStep={this.state.currentStep} _next={this._next} handleChange={this.handleChange} fjoldi={this.state.fjoldi} tegund={this.state.tegund} tegundverd={this.state.tegundverd} rifogforgun={this.state.rifogforgun} rifogforgunverd={this.state.rifogforgunverd} yfirfeld={this.state.yfirfeld} yfirfeldverd={this.state.yfirfeldverd} rennihurd={this.state.rennihurd} rennihurdverd={this.state.rennihurdverd} throskuldur={this.state.throskuldur} throskuldurverd={this.state.throskuldurverd} fellithroskuldurverd={this.state.fellithroskuldurverd} hurdarhunn={this.state.hurdarhunn} hurdarhunnverd={this.state.hurdarhunnverd} utskyring={this.state.utskyring_6} stk={this.state.stk} b60={this.state.b60} b30={this.state.b30}  />
                    <Step7 currentStep={this.state.currentStep} handleChange={this.handleChange} fjoldi={this.state.fjoldi} tegund={this.state.tegund} tegundverd={this.state.tegundverd} rifogforgun={this.state.rifogforgun} rifogforgunverd={this.state.rifogforgunverd} yfirfeld={this.state.yfirfeld} yfirfeldverd={this.state.yfirfeldverd} rennihurd={this.state.rennihurd} rennihurdverd={this.state.rennihurdverd} throskuldur={this.state.throskuldur} throskuldurverd={this.state.throskuldurverd} fellithroskuldurverd={this.state.fellithroskuldurverd} hurdarhunn={this.state.hurdarhunn} hurdarhunnverd={this.state.hurdarhunnverd} utskyring={this.state.utskyring_7} stk={this.state.stk} b60={this.state.b60} b30={this.state.b30}  />
                    {this.previousButton()}
                    {this.nextButton()}
                </form>
            </div>
        );
    }
}

function Step1(props) {
    if (props.currentStep !== 1) {
        return null
    }
    var rows = [], i = 0, len = 20;
    while (++i <= len) rows.push(i);
    return(
        <div className="form-group">
            <div className={'hurdarflotur hurd_' + props.tegund }>
                <div className={'throskuldur er_' + props.throskuldur }></div>
                <div className={"hurdahunnx f" + props.hurdarhunn}><span>x</span>{props.hurdarhunn}</div>
                <div className={'tegundicon tegund_hurd_' + props.tegund }></div>
                <div className={'forgunonoff h erforgun_' + props.rifogforgun }></div>
                <div className='fme'><span>x</span>{props.fjoldi}</div>
            </div>
            <h4 className='medutskyringu'>Fjöldi</h4>
            <div className="utskyring top">{props.utskyring}</div>
            <select id="fjoldi" name="fjoldi" value={props.fjoldi} onChange={props.handleChange} onKeyDown={props.handleKeyDown}>
                {rows.map(row =>
                    <option key={row} value={row}>{row}</option>
                )}
            </select>
        </div>
    );
}

function Step2(props) {
    if (props.currentStep !== 2) {
        return null
    }
    return(
        <div className="form-group">
            <div className={'hurdarflotur hurd_' + props.tegund }>
                <div className={'throskuldur er_' + props.throskuldur }></div>
                <div className={"hurdahunnx f" + props.hurdarhunn}><span>x</span>{props.hurdarhunn}</div>
                <div className={'tegundicon tegund_hurd_' + props.tegund }></div>
                <div className={'forgunonoff h erforgun_' + props.rifogforgun }></div>
                <div className='fme'><span>x</span>{props.fjoldi}</div>
            </div>
            <h4 className='medutskyringu'>Þarftu rif og förgun?</h4>
            <div className="utskyring top"><div dangerouslySetInnerHTML={{__html: props.utskyring}} /></div>
            <div className="toggle">
                <input type="radio" name="rifogforgun" checked={props.rifogforgun === "1"} value="1" id="tiu" onChange={props.handleChange}/>
                <label htmlFor="tiu" >Já</label>
                <input type="radio" name="rifogforgun" checked={props.rifogforgun === "0"} value="0" id="tuttugu"  onChange={props.handleChange}/>
                <label htmlFor="tuttugu">Nei</label>
            </div>
        </div>
    );
}

function Step3(props) {
    if (props.currentStep !== 3) {
        return null
    }
    return(
        <React.Fragment>
            <div className="form-group">
                <div className={'hurdarflotur hurd_' + props.tegund }>
                    <div className={'throskuldur er_' + props.throskuldur }></div>
                    <div className={"hurdahunnx f" + props.hurdarhunn}><span>x</span>{props.hurdarhunn}</div>
                    <div className={'tegundicon tegund_hurd_' + props.tegund }></div>
                    <div className={'forgunonoff h erforgun_' + props.rifogforgun }></div>
                    <div className='fme'><span>x</span>{props.fjoldi}</div>
                </div>
                <h4 className='medutskyringu'>Tegund</h4>
                <div className="utskyring top"><div dangerouslySetInnerHTML={{__html: props.utskyring}} /></div>
                <div className="toggle">
                    <input type="radio" name="tegund" value="1" checked={props.tegund === "1"} id="hefdbundin" onChange={props.handleChange}/>
                    <label htmlFor="hefdbundin">Hefðbundin</label>
                    <input type="radio" name="tegund" value="4" checked={props.tegund === "4"} id="yfirfelld" onChange={props.handleChange}/>
                    <label htmlFor="yfirfelld">Yfirfelldar</label>
                    <input type="radio" name="tegund" value="5" checked={props.tegund === "5"} id="rennihurd" onChange={props.handleChange}/>
                    <label htmlFor="rennihurd">Rennihurð</label>
                </div>
                <div className="toggle">
                    <input type="radio" name="tegund" value="2" checked={props.tegund === "2"} id="b30" onChange={props.handleChange}/>
                    <label htmlFor="b30">Eldvarnarhurð EI30</label>
                    <input type="radio" name="tegund" value="3" checked={props.tegund === "3"} id="b60" onChange={props.handleChange}/>
                    <label htmlFor="b60">Eldvarnar EI60</label>
                </div>
            </div>
        </React.Fragment>
    );
}

function Step4(props) {
    if (props.currentStep !== 4) {
        return null
    }
    return(
        <React.Fragment>
            <div className="form-group">
                <div className={'hurdarflotur hurd_' + props.tegund }>
                    <div className={'throskuldur er_' + props.throskuldur }></div>
                    <div className={"hurdahunnx f" + props.hurdarhunn}><span>x</span>{props.hurdarhunn}</div>
                    <div className={'tegundicon tegund_hurd_' + props.tegund }></div>
                    <div className={'forgunonoff h erforgun_' + props.rifogforgun }></div>
                    <div className='fme'><span>x</span>{props.fjoldi}</div>
                </div>
                <h4 className='medutskyringu'>Hverning þröskuld?</h4>
                <div className="utskyring top"><div dangerouslySetInnerHTML={{__html: props.utskyring}} /></div>
                <div className="toggle">
                    <input type="radio" name="throskuldur" value="0" checked={props.throskuldur === "0"} id="ekkifelli" onChange={props.handleChange}/>
                    <label htmlFor="ekkifelli">Þröskuldur</label>
                    <input type="radio" name="throskuldur" value="1" checked={props.throskuldur === "1"} id="felli" onChange={props.handleChange}/>
                    <label htmlFor="felli">Felliþröskuldur</label>
                    <input type="radio" name="throskuldur" value="2" checked={props.throskuldur === "2"} id="ekkithrosk" onChange={props.handleChange}/>
                    <label htmlFor="ekkithrosk">Enginn</label>
                </div>
            </div>
        </React.Fragment>
    );
}


function Step5(props) {
    if (props.currentStep !== 5) {
        return null
    }
    var rowsStk = [], istk = -1, lenstk = parseInt(props.fjoldi);
    while (++istk <= lenstk) rowsStk.push(istk);
    return(
        <React.Fragment>
            <div className="form-group">
                <div className={'hurdarflotur hurd_' + props.tegund }>
                    <div className={'throskuldur er_' + props.throskuldur }></div>
                    <div className={"hurdahunnx f" + props.hurdarhunn}><span>x</span>{props.hurdarhunn}</div>
                    <div className={'tegundicon tegund_hurd_' + props.tegund }></div>
                    <div className={'forgunonoff h erforgun_' + props.rifogforgun }></div>
                    <div className='fme'><span>x</span>{props.fjoldi}</div>
                </div>
                <h4 className='medutskyringu'>Hurðarhúnn</h4>
                <div className="utskyring top"><div dangerouslySetInnerHTML={{__html: props.utskyring}} /></div>
                <select id="hurdarhunn" name="hurdarhunn" value={props.hurdarhunn} onChange={props.handleChange} onKeyDown={props.handleKeyDown}>
                    {rowsStk.map(row =>
                        <option key={row} value={row}>{row}</option>
                    )}
                </select>
            </div>
        </React.Fragment>
    );
}

function Step6(props) {
    if (props.currentStep !== 6) {
        return null
    }

    let texti;
    let heildarverdid = 0;
    let verdreikn = 0;

    texti = "";
    verdreikn = 0;
    if (props.tegund === "1") {
        texti +=  "Hefðbundin<br/>" ;
        verdreikn = parseInt(props.stk);
    }

    if (props.tegund === "2") {
        texti +=  "Eldvarnarhurð EI30<br/>" ;
        verdreikn = parseInt(props.b30);
    }

    if (props.tegund === "3") {
        texti +=  "Eldvarnarhurð EI60<br/>" ;
        verdreikn = parseInt(props.b60);
    }

    if (props.tegund === "4") {
        texti +=  "Yfirfelldar<br/>" ;
        verdreikn = parseInt(props.yfirfeldverd);
    }

    if (props.tegund === "5") {
        texti +=  "Rennihurð<br/>" ;
        verdreikn = parseInt(props.rennihurdverd);
    }

    texti +=  "Fjöldi: " + props.fjoldi + "<br/>" ;
    heildarverdid = props.fjoldi * verdreikn;

    if (props.rifogforgun === "1") {
        heildarverdid += props.fjoldi * props.rifogforgunverd;
        texti +=  "Rif og förgun<br/>" ;
    }

    if (props.hurdarhunn === "0") {
    } else {
        heildarverdid += props.hurdarhunn * props.hurdarhunnverd;
        texti +=  "Hurðahúnar x "+props.hurdarhunn+"<br/>" ;
    }


    if (props.throskuldur === "0") {
        texti +=  "Þröskuldur <br/>" ;
        heildarverdid += props.fjoldi * props.throskuldurverd;
    }
    if (props.throskuldur === "1") {
        texti +=  "Felliþröskuldur <br/>" ;
        heildarverdid += props.fjoldi * props.fellithroskuldurverd;
    }
    if (props.throskuldur === "2") {
        texti +=  "Engin þröskuldur <br/>" ;
        heildarverdid += 0;
    }

    return(
        <React.Fragment>
            <div className="form-group">
                <div className={'hurdarflotur hurd_' + props.tegund }>
                    <div className={'throskuldur er_' + props.throskuldur }></div>
                    <div className={"hurdahunnx f" + props.hurdarhunn}><span>x</span>{props.hurdarhunn}</div>
                    <div className={'tegundicon tegund_hurd_' + props.tegund }></div>
                    <div className={'forgunonoff h erforgun_' + props.rifogforgun }></div>
                    <div className='fme'><span>x</span>{props.fjoldi}</div>
                </div>
                <h4>Þú hefur valið</h4>
                <div>
                    <div className="fatilbodtakki"><button className="btn btn-primary fatilbod" type="button" onClick={props._next}>Bóka tíma hér</button></div>
                    <div className="utskyring"><div dangerouslySetInnerHTML={{__html: props.utskyring_9}} /></div>
                </div>
                <div dangerouslySetInnerHTML={{__html: texti}}></div>
                <h4 class="verd">Verðáætlun:</h4>
                <NumberFormat value={heildarverdid} className="verd" displayType={'text'} thousandSeparator="." decimalSeparator=","
                    renderText={(value, props) => <div {...props}>{value} kr.</div>}
                />
                <div className="utskyring"></div>
                <p><strong>ATH Þessi verðáætlun er fyrir vinnulið og er með vsk.</strong></p>
            </div>
        </React.Fragment>
    );
}

function Step7(props) {
    if (props.currentStep !== 7) {
        return null
    }

    let texti;
    let tegundin = "";
    let heildarverdid = 0;
    let verdreikn = 0;
    let throskuldur = "";
    let throskuldurverd = 0;
    let rifogforgun = "";
    let rifogforgunverd = 0;
    let hurdarhunn = 0;
    let hurdarhunnverd = 0;

    texti = "";
    verdreikn = 0;
    if (props.tegund === "1") {
        texti +=  "Hefðbundin \n" ;
        tegundin +=  "Hefðbundin" ;
        verdreikn = parseInt(props.stk);
    }

    if (props.tegund === "2") {
        texti +=  "Eldvarnarhurð EI30 \n" ;
        tegundin +=  "Eldvarnarhurð EI30" ;
        verdreikn = parseInt(props.b30);
    }

    if (props.tegund === "3") {
        texti +=  "Eldvarnarhurð EI60 \n" ;
        tegundin +=  "Eldvarnarhurð EI60" ;
        verdreikn = parseInt(props.b60);
    }

    if (props.tegund === "4") {
        texti +=  "Yfirfelldar \n" ;
        tegundin +=  "Yfirfelldar" ;
        verdreikn = parseInt(props.yfirfeldverd);
    }

    if (props.tegund === "5") {
        texti +=  "Rennihurð \n";
        tegundin +=  "Rennihurð";
        verdreikn = parseInt(props.rennihurdverd);
    }

    texti +=  "Fjöldi: " + props.fjoldi + " \n" ;
    heildarverdid = props.fjoldi * verdreikn;

    if (props.rifogforgun === "1") {
        heildarverdid += props.fjoldi * props.rifogforgunverd;
        texti +=  "Rif og förgun \n";
        rifogforgun = "Rif og förgun";
        rifogforgunverd = props.rifogforgunverd;
    } else {
        rifogforgun = "Ekki Rif og förgun";
        rifogforgunverd = 0;
    }

    if (props.hurdarhunn === "0") {
        hurdarhunnverd = 0;
        hurdarhunn = 0;
    } else {
        heildarverdid += props.hurdarhunn * props.hurdarhunnverd; 
        texti +=  "Hurðahúnar x "+props.hurdarhunn+" \n" ;
        hurdarhunnverd = props.hurdarhunnverd;
        hurdarhunn = props.hurdarhunn;
    }

    if (props.throskuldur === "0") {
        texti +=  "Þröskuldur \n" ;
        heildarverdid += props.fjoldi * props.throskuldurverd;
        throskuldur = "Þröskuldur";
        throskuldurverd = props.throskuldurverd;
    }
    if (props.throskuldur === "1") {
        texti +=  "Felliþröskuldur \n" ;
        heildarverdid += props.fjoldi * props.fellithroskuldurverd;
        throskuldur = "Felliþröskuldur";
        throskuldurverd = props.fellithroskuldurverd;
    }
    if (props.throskuldur === "2") {
        texti +=  "Enginn þröskuldur \n" ;
        heildarverdid += 0;
        throskuldur = "Enginn þröskuldur";
        throskuldurverd = 0;
    }

    return(
        <React.Fragment>
            <div className="wrapperReikn">
                <h3>Bóka tíma</h3>
                <form method="post" action="https://content.thakco.is/ajax/createaposthurdir.php" onSubmit={props.handleValidation}>

                    <input type="text" name="Nafn" className="inputform" placeholder="Nafn" required />
                    <input type="email" name="Netfang" className="inputform" placeholder="Netfang" required />
                    <input type="text" name="Simi" className="inputform" placeholder="Símanúmer" required />
                    <input type="text" name="kennitala" className="inputform" placeholder="Kennitala" required />
                    <input type="text" name="heimilisfang" className="inputform" placeholder="Heimilisfang" required />
                    <input type="text" name="postcode" className="inputform postcode" placeholder="Póstnr." required />
                    <input type="text" name="stadur" className="inputform stadur" placeholder="Bær/Borg" required />

                    <textarea name="athugasemdir" className="athugasemdir" placeholder="Athugasemdir"></textarea>

                    <input type="hidden" name="heildarverd" value={heildarverdid} />
                    <input type="hidden" name="verdreikn" value={verdreikn} />
                    <input type="hidden" name="tegund" value={tegundin} />
                    <input type="hidden" name="content" value={texti} />
                    <input type="hidden" name="flatarmal" value={props.fjoldi} />
                    <input type="hidden" name="throskuldur" value={throskuldur} />
                    <input type="hidden" name="throskuldurverd" value={throskuldurverd} />
                    <input type="hidden" name="rifogforgun" value={rifogforgun} />
                    <input type="hidden" name="rifogforgunverd" value={rifogforgunverd} />
                    <input type="hidden" name="hurdarhunn" value={hurdarhunn} />
                    <input type="hidden" name="hurdarhunnverd" value={hurdarhunnverd} />

                    <input type="submit" value="Senda" className="next" />
                </form>
            </div>
        </React.Fragment>
    );
}


export default Innihurdir;