import React, { Component } from 'react';

export class Millivegir extends Component {

    componentDidMount () {

        this.onResize();
        (new ResizeObserver(_ => this.onResize())).observe(document.body);
    }

    onResize () {
        // Obviously you can format your data object however you want
        window.parent.postMessage({
            type: 'resize',
            height: document.body.scrollHeight,
        }, '*');
    }

    render() {
        return (
            <div className="wrapperReikn">
                <h2 className='validreikn'>Milliveggir <a href="/" className='backhome'>Til baka á forsíðu</a></h2>
            </div>
        );
    }
}

export default Millivegir;