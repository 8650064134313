import React, { Component } from 'react';
import axios from 'axios';
import NumberFormat from 'react-number-format';

export class Innrettingar extends Component {
    
    constructor(props) {
        super(props)
        this.state = {
            currentStep: 1,
            flatarmal:  200,
            flatarmalefri:  0,
            flatarmalheild:  200,
            rifogforgun: "0",
            eldavel: "0",
            vaskur: "0",
            holdur: "0",
            samsetning: "0",
            uppsetning: "0",
            heildverd: 0,
            metraverd: 0,
            rifogforgunverd: 0,
            samsetingaverd: 0,
            uppsetningaverd: 0,
            staerdskiptirmali: "",
            holdurverd: 0,
            vaskurverd: 0,
            eldavelverd: 0,
            utskyring_1: '',
            utskyring_2: 'Hér vantar texta um hversu frábært það er að fá rif og förgun.',
            utskyring_3: '',
            utskyring_4: '',
            utskyring_5: '',
            utskyring_6: '',
            utskyring_7: '',
            verkbyrjun:'',
        }
    }

    componentDidMount () {
        this.onResize();
        (new ResizeObserver(_ => this.onResize())).observe(document.body);

        axios.get('https://content.thakco.is/wp-json/wp/v2/pages/1148')
        .then(res => this.setState({
            metraverd: res.data.acf.metraverd,
            rifogforgunverd: res.data.acf.rifogforgunverd,
            samsetingaverd: res.data.acf.samsetingaverd,
            uppsetningaverd: res.data.acf.uppsetningaverd,
            holdurverd: res.data.acf.holdurverd,
            vaskurverd: res.data.acf.vaskurverd,
            eldavelverd: res.data.acf.eldavelverd,
            utskyring_1: res.data.acf.utskyring_1,
            utskyring_2: res.data.acf.utskyring_2,
            utskyring_3: res.data.acf.utskyring_3,
            utskyring_4: res.data.acf.utskyring_4,
            utskyring_5: res.data.acf.utskyring_5,
            utskyring_6: res.data.acf.utskyring_6,
            utskyring_7: res.data.acf.utskyring_7,
            verkbyrjun: res.data.acf.verkbyrjun
        }))
        .catch(err => console.log(err))

    }

    onResize () {
        // Obviously you can format your data object however you want
        window.parent.postMessage({
            type: 'resize',
            height: document.body.scrollHeight,
        }, '*');
    }

    handleChange = event => {
        const {name, value} = event.target
        this.setState({
            [name]: value
        })
    }

    handleFMChange = event => {
        let { value, min, max } = event.target;
        let flatarmalefri = this.state.flatarmalefri;
        let flatarmalheild = 0;
        flatarmalheild = parseInt(flatarmalefri)+parseInt(value);

        if (parseInt(value) < 30) {
            this.setState({"staerdskiptirmali": "Má ekki vera undir " + min });
        } else if (parseInt(value) > 1400) {
            this.setState({"staerdskiptirmali": "Má ekki vera meira en " + max });
        } else {
            this.setState({"staerdskiptirmali": ""});
        }
        this.setState({"flatarmalheild": flatarmalheild });
        this.setState({"flatarmal": value });
    }

    handleFMEChange = event => {
        let { value } = event.target;
        let flatarmal = this.state.flatarmal;
        let flatarmalheild = 0;
        flatarmalheild = parseInt(flatarmal) + parseInt(value);
        
        this.setState({"flatarmalheild": flatarmalheild });
        this.setState({"flatarmalefri": value });
    }

    _fatibod = () => {
        let currentStep = this.state.currentStep
        currentStep = 9
        console.log(currentStep)
        this.setState({
            currentStep: currentStep
        })
    }

    _next = () => {
        let flatarmal = this.state.flatarmal
        let currentStep = this.state.currentStep
        if (flatarmal >= 10 && flatarmal <= 500) {
            currentStep = currentStep >= 8? 9: currentStep + 1
            this.setState({
                currentStep: currentStep
            })
        }
    }

    _prev = () => {
        let currentStep = this.state.currentStep
        currentStep = currentStep <= 1? 1: currentStep - 1
        this.setState({
            currentStep: currentStep
        })
    }

    previousButton() {
        let currentStep = this.state.currentStep;
        if(currentStep !==1){
            return (
            <button className="btn btn-secondary" type="button" onClick={this._prev}>Til baka</button>
            )
        }
        return null;
    }

    nextButton() {
        let currentStep = this.state.currentStep;
        if(currentStep < 7){
            return (
                <button className="btn btn-primary float-right" type="button" onClick={this._next}>Áfram</button>
            )
        }
        return null;
    }

    faformlegttilbodButton() {
        let currentStep = this.state.currentStep;
        if(currentStep === 7){
            return (
                <div>
                    <div className="fatilbodtakki"><button className="btn btn-primary fatilbod" type="button" onClick={this._next}>Fá formlegt tilboð</button></div>
                    <div className="utskyring"><div dangerouslySetInnerHTML={{__html: this.state.utskyring_8}} /></div>
                </div>
            )
        }
        return null;
    }

    render() {
        return (
            <div className="wrapperReikn">
                <h2 className='validreikn'>Innréttingar <a href="/val" className='backhome'>Til baka á forsíðu</a></h2>
                <form onSubmit={this.handleSubmit}>
                    <Step1 currentStep={this.state.currentStep} handleFMChange={this.handleFMChange} handleFMEChange={this.handleFMEChange} staerdskiptirmali={this.state.staerdskiptirmali} handleChange={this.handleChange}flatarmalheild={this.state.flatarmalheild} flatarmal={this.state.flatarmal} flatarmalefri={this.state.flatarmalefri} uppsetning={this.state.uppsetning} rifogforgun={this.state.rifogforgun} utskyring={this.state.utskyring_1} eldavel={this.state.eldavel} vaskur={this.state.vaskur} holdur={this.state.holdur} samsetning={this.state.samsetning} metraverd={this.state.metraverd} rifogforgunverd={this.state.rifogforgunverd} samsetingaverd={this.state.samsetingaverd} uppsetningaverd={this.state.uppsetningaverd} holdurverd={this.state.holdurverd} vaskurverd={this.state.vaskurverd} eldavelverd={this.state.eldavelverd} /> 
                    <Step2 currentStep={this.state.currentStep} handleChange={this.handleChange} flatarmalheild={this.state.flatarmalheild} flatarmal={this.state.flatarmal} flatarmalefri={this.state.flatarmalefri} uppsetning={this.state.uppsetning} rifogforgun={this.state.rifogforgun} utskyring={this.state.utskyring_2} eldavel={this.state.eldavel} vaskur={this.state.vaskur} holdur={this.state.holdur} samsetning={this.state.samsetning} />
                    <Step3 currentStep={this.state.currentStep} handleChange={this.handleChange} flatarmalheild={this.state.flatarmalheild} flatarmal={this.state.flatarmal} flatarmalefri={this.state.flatarmalefri} uppsetning={this.state.uppsetning} rifogforgun={this.state.rifogforgun} utskyring={this.state.utskyring_3} eldavel={this.state.eldavel} vaskur={this.state.vaskur} holdur={this.state.holdur} samsetning={this.state.samsetning} />
                    <Step4 currentStep={this.state.currentStep} handleChange={this.handleChange} flatarmalheild={this.state.flatarmalheild} flatarmal={this.state.flatarmal} flatarmalefri={this.state.flatarmalefri} uppsetning={this.state.uppsetning} rifogforgun={this.state.rifogforgun} utskyring={this.state.utskyring_4} eldavel={this.state.eldavel} vaskur={this.state.vaskur} holdur={this.state.holdur} samsetning={this.state.samsetning} />
                    <Step5 currentStep={this.state.currentStep} handleChange={this.handleChange} flatarmalheild={this.state.flatarmalheild} flatarmal={this.state.flatarmal} flatarmalefri={this.state.flatarmalefri} uppsetning={this.state.uppsetning} rifogforgun={this.state.rifogforgun} utskyring={this.state.utskyring_5} eldavel={this.state.eldavel} vaskur={this.state.vaskur} holdur={this.state.holdur} samsetning={this.state.samsetning} />
                    <Step6 currentStep={this.state.currentStep} handleChange={this.handleChange} flatarmalheild={this.state.flatarmalheild} flatarmal={this.state.flatarmal} flatarmalefri={this.state.flatarmalefri} uppsetning={this.state.uppsetning} rifogforgun={this.state.rifogforgun} utskyring={this.state.utskyring_6} eldavel={this.state.eldavel} vaskur={this.state.vaskur} holdur={this.state.holdur} samsetning={this.state.samsetning} />
                    <Step7 currentStep={this.state.currentStep} _next={this._next} handleChange={this.handleChange} flatarmalheild={this.state.flatarmalheild} flatarmal={this.state.flatarmal} flatarmalefri={this.state.flatarmalefri} uppsetning={this.state.uppsetning} rifogforgun={this.state.rifogforgun} utskyring={this.state.utskyring_7} eldavel={this.state.eldavel} vaskur={this.state.vaskur} holdur={this.state.holdur} samsetning={this.state.samsetning} metraverd={this.state.metraverd} rifogforgunverd={this.state.rifogforgunverd} samsetingaverd={this.state.samsetingaverd} uppsetningaverd={this.state.uppsetningaverd} holdurverd={this.state.holdurverd} vaskurverd={this.state.vaskurverd} eldavelverd={this.state.eldavelverd} />
                    <Step8 currentStep={this.state.currentStep} handleChange={this.handleChange} flatarmalheild={this.state.flatarmalheild} flatarmal={this.state.flatarmal} flatarmalefri={this.state.flatarmalefri} uppsetning={this.state.uppsetning} rifogforgun={this.state.rifogforgun} utskyring={this.state.utskyring_7} eldavel={this.state.eldavel} vaskur={this.state.vaskur} holdur={this.state.holdur} samsetning={this.state.samsetning} metraverd={this.state.metraverd} rifogforgunverd={this.state.rifogforgunverd} samsetingaverd={this.state.samsetingaverd} uppsetningaverd={this.state.uppsetningaverd} holdurverd={this.state.holdurverd} vaskurverd={this.state.vaskurverd} eldavelverd={this.state.eldavelverd} />
                    {this.previousButton()}
                    {this.nextButton()}
                </form>
            </div>
        );

    }
}

function Step1(props) {
    if (props.currentStep !== 1) {
        return null
    }
    var rows = [], i = 29, len = 1400;
    while (++i <= len) rows.push(i);
    return(
        <div className="form-group">
            <div className='skapaflotur'>
                <div className={'linur show' + props.flatarmal}>
                    <span id="lina1"></span>
                    <span id="lina2"></span>
                    <span id="lina3"></span>
                    <span id="lina4"></span>
                    <span id="lina5"></span>
                    <span id="lina6"></span>
                </div>
                <div className={"innrif errif" + props.rifogforgun}></div>
                <div className={"innsam ersam" + props.samsetning}></div>
                <div className={"innupp erupp" + props.uppsetning}></div>
                <div className={"innvaskur ervaskur" + props.vaskur}></div>
                <div className={"inneldavel ereldavel" + props.eldavel}></div>
                <div className='lme'><span><i>Neðri</i>{props.flatarmal} cm</span></div>
                <div className='lmeefri'><span><i>Efrir</i>{props.flatarmalefri} cm</span></div>
            </div>
            <h4 className='medutskyringu'>Hvað er flöturinn langur (cm)?</h4>
            <div className="utskyring top">{props.utskyring}</div>
            <div className="half">
                <h4 className='medutskyringu auka'>Efri skápar</h4>
                <input value={props.flatarmalefri} id="flatarmalefri" name="flatarmalefri" type="number" onChange={props.handleFMEChange} min="0" max="1400"/> cm
            </div><div className="half">
                <h4 className='medutskyringu auka'>Neðri skápar</h4>
                <input value={props.flatarmal} id="flatarmal" name="flatarmal" type="number" onChange={props.handleFMChange} min="30" max="1400"/> cm
            </div>
            <div className="villa">{props.staerdskiptirmali}</div>
            <div className="utskyring">Minnst: 30 cm, mest 1400 cm</div>
        </div>
    );
}

function Step2(props) {
    if (props.currentStep !== 2) {
        return null
    }
    return(
        <div className="form-group">
            <div className='skapaflotur'>
                <div className={'linur show' + props.flatarmal}>
                    <span id="lina1"></span>
                    <span id="lina2"></span>
                    <span id="lina3"></span>
                    <span id="lina4"></span>
                    <span id="lina5"></span>
                    <span id="lina6"></span>
                </div>
                <div className={"innrif errif" + props.rifogforgun}></div>
                <div className={"innsam ersam" + props.samsetning}></div>
                <div className={"innupp erupp" + props.uppsetning}></div>
                <div className={"innvaskur ervaskur" + props.vaskur}></div>
                <div className={"inneldavel ereldavel" + props.eldavel}></div>
                <div className='lme'><span><i>Neðri</i>{props.flatarmal} cm</span></div>
                <div className='lmeefri'><span><i>Efrir</i>{props.flatarmalefri} cm</span></div>
            </div>
            <h4 className='medutskyringu'>Viltu rif og förgun?</h4>
            <div className="utskyring top"><div dangerouslySetInnerHTML={{__html: props.utskyring}} /></div>
            <div className="toggle">
                <input type="radio" name="rifogforgun" value="1" checked={props.rifogforgun === "1"} id="tiu" onChange={props.handleChange}/>
                <label htmlFor="tiu" >Já</label>
                <input type="radio" name="rifogforgun" value="0" checked={props.rifogforgun === "0"} id="tuttugu"  onChange={props.handleChange}/>
                <label htmlFor="tuttugu">Nei</label>
            </div>
        </div>
    );
}

function Step3(props) {
    if (props.currentStep !== 3) {
        return null
    }
    var rowsStk = [], istk = -1, lenstk = 20;
    while (++istk <= lenstk) rowsStk.push(istk);
    return(
        <React.Fragment>
            <div className="form-group">
                <div className='skapaflotur'>
                    <div className={'linur show' + props.flatarmal}>
                        <span id="lina1"></span>
                        <span id="lina2"></span>
                        <span id="lina3"></span>
                        <span id="lina4"></span>
                        <span id="lina5"></span>
                        <span id="lina6"></span>
                    </div>
                    <div className={"innrif errif" + props.rifogforgun}></div>
                    <div className={"innsam ersam" + props.samsetning}></div>
                    <div className={"innupp erupp" + props.uppsetning}></div>
                    <div className={"innvaskur ervaskur" + props.vaskur}></div>
                    <div className={"inneldavel ereldavel" + props.eldavel}></div>
                    <div className='lme'><span><i>Neðri</i>{props.flatarmal} cm</span></div>
                    <div className='lmeefri'><span><i>Efrir</i>{props.flatarmalefri} cm</span></div>
                </div>
                <h4 className='medutskyringu'>Þarftu samsetningu?</h4>
                <div className="utskyring top"><div dangerouslySetInnerHTML={{__html: props.utskyring}} /></div>
                <h4 className='medutskyringu auka'>Hversu margir skápar?</h4>
                <select id="samsetning" name="samsetning" value={props.samsetning} onChange={props.handleChange} onKeyDown={props.handleKeyDown}>
                    {rowsStk.map(row =>
                        <option key={row} value={row}>{row}</option>
                    )}
                </select>
            </div>
        </React.Fragment>
    );
}
/*
function Step4(props) {
    if (props.currentStep !== 4) {
        return null
    }
    return(
        <React.Fragment>
            <div className="form-group">
                <div className='skapaflotur'>
                    <div className={'linur show' + props.flatarmal}>
                        <span id="lina1"></span>
                        <span id="lina2"></span>
                        <span id="lina3"></span>
                        <span id="lina4"></span>
                        <span id="lina5"></span>
                        <span id="lina6"></span>
                    </div>
                    <div className={"innrif errif" + props.rifogforgun}></div>
                    <div className={"innsam ersam" + props.samsetning}></div>
                    <div className={"innupp erupp" + props.uppsetning}></div>
                    <div className={"innvaskur ervaskur" + props.vaskur}></div>
                    <div className={"inneldavel ereldavel" + props.eldavel}></div>
                    <div className='lme'><span>{props.flatarmal} cm</span></div>
                    <div className='lmeefri'><span>{props.flatarmalefri} cm</span></div>
                </div>
                <h4 className='medutskyringu'>Þarftu uppsetning?</h4>
                <div className="utskyring top"><div dangerouslySetInnerHTML={{__html: props.utskyring}} /></div>
                <div className="toggle">
                    <input type="radio" name="uppsetning" value="1"  checked={props.uppsetning === "1"} id="uppsetning" onChange={props.handleChange}/>
                    <label htmlFor="uppsetning">Já</label>
                    <input type="radio" name="uppsetning" value="0" checked={props.uppsetning === "0"} id="uppsetningN" onChange={props.handleChange}/>
                    <label htmlFor="uppsetningN">Nei</label>
                </div>
            </div>
        </React.Fragment>
    );
}
*/

function Step4(props) {
    if (props.currentStep !== 4) {
        return null
    }
    var rowsStk = [], istk = -1, lenstk = 20;
    while (++istk <= lenstk) rowsStk.push(istk);
    return(
        <React.Fragment>
            <div className="form-group">
                <div className='skapaflotur'>
                    <div className={'linur show' + props.flatarmal}>
                        <span id="lina1"></span>
                        <span id="lina2"></span>
                        <span id="lina3"></span>
                        <span id="lina4"></span>
                        <span id="lina5"></span>
                        <span id="lina6"></span>
                    </div>
                    <div className={"innrif errif" + props.rifogforgun}></div>
                    <div className={"innsam ersam" + props.samsetning}></div>
                    <div className={"innupp erupp" + props.uppsetning}></div>
                    <div className={"innvaskur ervaskur" + props.vaskur}></div>
                    <div className={"inneldavel ereldavel" + props.eldavel}></div>
                    <div className='lme'><span><i>Neðri</i>{props.flatarmal} cm</span></div>
                    <div className='lmeefri'><span><i>Efrir</i>{props.flatarmalefri} cm</span></div>
                </div>
                <h4 className='medutskyringu'>Hversu margar höldur?</h4>
                <div className="utskyring top">{props.utskyring_3}</div>
                <select id="holdur" name="holdur" value={props.holdur} onChange={props.handleChange} onKeyDown={props.handleKeyDown}>
                    {rowsStk.map(row =>
                        <option key={row} value={row}>{row}</option>
                    )}
                </select>
            </div>
        </React.Fragment>
    );
}

function Step5(props) {
    if (props.currentStep !== 5) {
        return null
    }
    return(
        <React.Fragment>
            <div className="form-group">
                <div className='skapaflotur'>
                    <div className={'linur show' + props.flatarmal}>
                        <span id="lina1"></span>
                        <span id="lina2"></span>
                        <span id="lina3"></span>
                        <span id="lina4"></span>
                        <span id="lina5"></span>
                        <span id="lina6"></span>
                    </div>
                    <div className={"innrif errif" + props.rifogforgun}></div>
                    <div className={"innsam ersam" + props.samsetning}></div>
                    <div className={"innupp erupp" + props.uppsetning}></div>
                    <div className={"innvaskur ervaskur" + props.vaskur}></div>
                    <div className={"inneldavel ereldavel" + props.eldavel}></div>
                    <div className='lme'><span><i>Neðri</i>{props.flatarmal} cm</span></div>
                    <div className='lmeefri'><span><i>Efrir</i>{props.flatarmalefri} cm</span></div>
                </div>
                <h4 className='medutskyringu'>Fræsing + uppsetning vaskur? {props.varkur}</h4>
                <div className="utskyring top"><div dangerouslySetInnerHTML={{__html: props.utskyring}} /></div>
                <div className="toggle">
                    <input type="radio" name="vaskur" value="1" checked={props.vaskur === "1"} id="vaskur" onChange={props.handleChange}/>
                    <label htmlFor="vaskur">Já</label>
                    <input type="radio" name="vaskur" value="0" checked={props.vaskur === "0"} id="vaskurn" onChange={props.handleChange}/>
                    <label htmlFor="vaskurn">Nei</label>
                </div>
            </div>
        </React.Fragment>
    );
}

function Step6(props) {
    if (props.currentStep !== 6) {
        return null
    }
    return(
        <React.Fragment>
            <div className="form-group">
                <div className='skapaflotur'>
                    <div className={'linur show' + props.flatarmal}>
                        <span id="lina1"></span>
                        <span id="lina2"></span>
                        <span id="lina3"></span>
                        <span id="lina4"></span>
                        <span id="lina5"></span>
                        <span id="lina6"></span>
                    </div>
                    <div className={"innrif errif" + props.rifogforgun}></div>
                    <div className={"innsam ersam" + props.samsetning}></div>
                    <div className={"innupp erupp" + props.uppsetning}></div>
                    <div className={"innvaskur ervaskur" + props.vaskur}></div>
                    <div className={"inneldavel ereldavel" + props.eldavel}></div>
                    <div className='lme'><span><i>Neðri</i>{props.flatarmal} cm</span></div>
                    <div className='lmeefri'><span><i>Efrir</i>{props.flatarmalefri} cm</span></div>
                </div>
                <h4 className='medutskyringu'>Fræsing + uppsetning helluborð?</h4>
                <div className="utskyring top"><div dangerouslySetInnerHTML={{__html: props.utskyring}} /></div>
                <div className="toggle">
                    <input type="radio" name="eldavel" value="1" checked={props.eldavel === "1"} id="eldavel" onChange={props.handleChange}/>
                    <label htmlFor="eldavel">Já</label>
                    <input type="radio" name="eldavel" value="0" checked={props.eldavel === "0"} id="eldaveln" onChange={props.handleChange}/>
                    <label htmlFor="eldaveln">Nei</label>
                </div>
            </div>
        </React.Fragment>
    );
}

function Step7(props) {
    if (props.currentStep !== 7) {
        return null
    }

    let texti;
    let heildarverdid = 0;

    texti =  "Heildar flötur: " + props.flatarmalheild + " cm <br/>" ;
    heildarverdid = props.flatarmalheild * props.metraverd;

    texti +=  "Efri skápar: " + props.flatarmalefri + " cm <br/>" ;
    texti +=  "Neðri skápar: " + props.flatarmal + " cm <br/>" ;

    if (props.rifogforgun === "1") {
        heildarverdid += props.flatarmalheild * props.rifogforgunverd;
        texti +=  "Rif og förgun<br/>" ;
    }

    if (props.holdur === "0") {
    } else {
        heildarverdid += parseInt(props.holdur) * props.holdurverd;
        texti +=  "Höldur x "+props.holdur+"<br/>" ;
    }

    if (props.samsetning === "0") {
    } else {
        heildarverdid += props.samsetning * props.samsetingaverd;
        texti +=  "Samsetning x "+props.samsetning+" skápar<br/>" ;
    }

    if (props.vaskur === "1") {
        heildarverdid += 1 * props.vaskurverd;
        texti +=  "Fræsing + Uppsetning vaskur? <br/>" ;
    }

    if (props.eldavel === "1") {
        heildarverdid += 1 * props.eldavelverd;
        texti +=  "Fræsing + Uppsetning helluborð?<br/>" ;
    }

    return(
        <React.Fragment>
            <div className="form-group">
                <div className='skapaflotur'>
                    <div className={'linur show' + props.flatarmal}>
                        <span id="lina1"></span>
                        <span id="lina2"></span>
                        <span id="lina3"></span>
                        <span id="lina4"></span>
                        <span id="lina5"></span>
                        <span id="lina6"></span>
                    </div>
                    <div className={"innrif errif" + props.rifogforgun}></div>
                    <div className={"innsam ersam" + props.samsetning}></div>
                    <div className={"innupp erupp" + props.uppsetning}></div>
                    <div className={"innvaskur ervaskur" + props.vaskur}></div>
                    <div className={"inneldavel ereldavel" + props.eldavel}></div>
                    <div className='lme'><span><i>Neðri</i>{props.flatarmal} cm</span></div>
                    <div className='lmeefri'><span><i>Efrir</i>{props.flatarmalefri} cm</span></div>
                </div>
                <h4>Þú hefur valið</h4>
                <div>
                    <div className="fatilbodtakki"><button className="btn btn-primary fatilbod" type="button" onClick={props._next}>Bóka tíma hér</button></div>
                    <div className="utskyring"><div dangerouslySetInnerHTML={{__html: props.utskyring_9}} /></div>
                </div>
                <div dangerouslySetInnerHTML={{__html: texti}}></div>
                <h4 class="verd">Verðáætlun:</h4>
                <NumberFormat value={heildarverdid} className="verd" displayType={'text'} thousandSeparator="." decimalSeparator=","
                        renderText={(value, props) => <div {...props}>{value} kr.</div>}
                />
                <div className="utskyring"></div>
                <p><strong>Þessi verðáætlun er fyrir vinnulið og er með vsk</strong></p>
            </div>
        </React.Fragment>
    );
}

function Step8(props) {
    if (props.currentStep !== 8) {
        return null
    }

    let texti;
    let heildarverdid = 0;

    texti =  "Heildar flötur: " + props.flatarmalheild + " cm \n" ;
    heildarverdid = props.flatarmalheild * props.metraverd;

    texti +=  "Efri skápar: " + props.flatarmalefri + " cm  \n" ;
    texti +=  "Neðri skápar: " + props.flatarmal + " cm \n" ;

    if (props.rifogforgun === "1") {
        heildarverdid += props.flatarmalheild * props.rifogforgunverd * props.rifogforgun;
        texti +=  "Rif og förgun \n" ;
    }

    if (props.holdur === "0") {
    } else {
        heildarverdid += parseInt(props.holdur) * props.holdurverd;
        texti +=  "Höldur x "+parseInt(props.holdur)+" \n" ;
    }

    if (props.samsetning === "1") {
        heildarverdid += parseInt(props.samsetning) * props.samsetingaverd;
        texti +=  "Samsetning x "+props.samsetning+" skápar \n" ; ;
    }

    if (props.vaskur === "1") {
        heildarverdid += 1 * props.vaskurverd;
        texti +=  "Fræsing + uppsetning vaskur? \n" ;
    }

    if (props.eldavel === "1") {
        heildarverdid += 1 * props.eldavelverd;
        texti +=  "Fræsing + uppsetning helluborð? \n" ;
    }

    return(
        <React.Fragment>
            <div className="wrapperReikn">
                <h3>Bóka tíma</h3>
                <form method="post" action="https://content.thakco.is/ajax/createapostinnrettingar.php" onSubmit={props.handleValidation}>

                    <input type="text" name="Nafn" className="inputform" placeholder="Nafn" required />
                    <input type="email" name="Netfang" className="inputform" placeholder="Netfang" required />
                    <input type="text" name="Simi" className="inputform" placeholder="Símanúmer" required />
                    <input type="text" name="kennitala" className="inputform" placeholder="Kennitala" required />
                    <input type="text" name="heimilisfang" className="inputform" placeholder="Heimilisfang" required />
                    <input type="text" name="postcode" className="inputform postcode" placeholder="Póstnr." required />
                    <input type="text" name="stadur" className="inputform stadur" placeholder="Bær/Borg" required />

                    <textarea name="athugasemdir" className="athugasemdir" placeholder="Athugasemdir"></textarea>

                    <input type="hidden" name="content" value={texti} />

                    <input type="hidden" name="heildarverd" value={heildarverdid} />
                    <input type="hidden" name="hurdarhunn" value={props.holdur} />
                    <input type="hidden" name="rifogforgun" value={props.rifogforgun} />
                    <input type="hidden" name="samsetning" value={props.samsetning} />
                    <input type="hidden" name="uppsetning" value={props.uppsetning} />
                    <input type="hidden" name="flatarmal" value={props.flatarmalheild} />
                    <input type="hidden" name="flatarmalefri" value={props.flatarmalefri} />
                    <input type="hidden" name="flatarmalnedri" value={props.flatarmal} />
                    <input type="hidden" name="vaskur" value={props.vaskur} />
                    <input type="hidden" name="eldavel" value={props.eldavel} />
                    <input type="hidden" name="metraverd" value={props.metraverd} />
                    <input type="hidden" name="rifogforgunverd" value={props.rifogforgunverd} />
                    <input type="hidden" name="samsetingaverd" value={props.samsetingaverd} />
                    <input type="hidden" name="uppsetningaverd" value={props.uppsetningaverd} />
                    <input type="hidden" name="holdurverd" value={props.holdurverd} />
                    <input type="hidden" name="vaskurverd" value={props.vaskurverd} />
                    <input type="hidden" name="eldavelverd" value={props.eldavelverd} />

                    <input type="submit" value="Senda" className="next" />

                </form>
            </div>
        </React.Fragment>
    );
}



export default Innrettingar;