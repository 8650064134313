import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import Forsida from './Views/Forsida';
import Reiknadu from './Views/Reiknadu';
import Bara from './Views/Bara';
import Parket from './Views/Parket';
import Innihurdir from './Views/Innihurdir';
import Millivegir from './Views/Millivegir';
import Innrettingar from './Views/Innrettingar';


import Takk from './Views/Takk';
import Stadfest from './Views/Stadfest'

function App() {
    return (
        <div className="App">
        <BrowserRouter>
        <div>
            <Switch>
                <Route exact path="/" component={Forsida} />
                <Route exact path="/val" component={Forsida} />
                <Route exact path="/thakpappi" component={Reiknadu} />
                <Route exact path="/thakbara" component={Bara} />
                <Route exact path="/parket" component={Parket} />
                <Route exact path="/innihurdir" component={Innihurdir} />
                <Route exact path="/milliveggir" component={Millivegir} />
                <Route exact path="/innrettingar" component={Innrettingar} />

                <Route path="/takkfyrir" component={Takk} />
                <Route path="/stadfestpontun" component={Stadfest} />
            </Switch>
        </div>
        </BrowserRouter>

        </div>
    );
}

export default App;